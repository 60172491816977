import Api from '../api';
import Constants from "../constants";
export default {
    namespaced: true,
    state: {
        token: null,
        user: null
    },
    mutations: {
        SET_TOKEN (state, token) {
            state.token = token;
        },
        SET_USER (state, data) {
            state.user = data;
        }
    },
    getters: {
        authenticated(state) {
            return state.token && state.user;
        },
        user(state) {
            return state.user;
        }
    },
    actions: {
        async login({ dispatch }, credentials) {

            let response = await Api.login(credentials);
            return dispatch('attempt', response.data.token);
        },
        async register({ dispatch }, credentials) {

            let response = await Api.register(credentials);
            return dispatch('attempt', response.data.token);
        },
        async attempt({ commit, state }, token) {

            if(token) {
                commit(Constants.SET_TOKEN, token);
            }

            if(!state.token) {
                return;
            }

            try{
                let response = await Api.me();
                commit(Constants.SET_USER, response.data)

            } catch (e) {
                commit(Constants.SET_TOKEN, null);
                commit(Constants.SET_USER, null);
            }
        },
        logout({ commit }) {
            return Api.logout().then(() => {
                commit(Constants.SET_TOKEN, null);
                commit(Constants.SET_USER, null);
            })
        }
    }
};
