<template>
    <loan-card>
        <template v-slot:header>
            <nav-tabs></nav-tabs>
        </template>
        <template v-slot:body>
            <div class="container px-0">
                <div class="row">
                    <div class="col-md-12 border p-3 m-3 bg-light" style="border-radius: 4px;">
                        <div class="text-center p-3 mb-4 border-bottom">
                            <h4 class="text-muted"><span>Collateral Requirements</span></h4>
                        </div>
                        <div class="form-group">
                            <label for="collateral-type" class="h6">Collateral Type</label>
                            <div class="list-group" id="collateral-type">
                                <div class="list-group-item" v-for="(cz, i) in collateral">
                                    <div class="form-check">
                                        <input class="form-check-input" name="collateral_type" v-model="collateral_type" type="checkbox" :value="cz.id" :id="`check-${i}-box`">
                                        <label class="form-check-label" :for="`check-${i}-box`">
                                            {{ cz.collateral_name }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group" v-if="hasCollateral">
                            <label for="collateral-values">Collateral Value & Location</label>
                            <div class="list-group mb-3 border-danger"
                                 id="collateral-values" v-for="(v, p) in final_collateral_value" :key="v.id">
                                <div class="list-group-item text-dark">
                                    Value of {{ v.name }}
                                </div>
                                <div class="list-group-item p-1">
                                    <div class="input-group m-0">
                                        <VueNumberFormat :id="`${v.id}-collateral-value`"
                                                         v-model:value="collateral_value[v.id].collateral_value" class="form-control"></VueNumberFormat>
                                        <div class="input-group-append">
                                            <span class="input-group-text">UGX</span>
                                        </div>
                                        <div class="invalid-feedback" :key="`${v.id}-error`">
                                            Collateral value is required
                                        </div>
                                    </div>
                                </div>
                                <template v-if="collateral_value[v.id].location_required">
                                    <div class="list-group-item text-dark">
                                        Where is your collateral located?
                                    </div>
                                    <div class="list-group-item text-dark">
                                        <div class="form-check">
                                            <input class="form-check-input" v-model="collateral_value[v.id].collateral_location" type="radio" value="current_location" :id="`check-${v.id}-current`">
                                            <label class="form-check-label" :for="`check-${i}-current`">
                                                Current location
                                            </label>
                                        </div>
                                    </div>
                                    <div class="list-group-item text-dark">
                                        <div class="form-check">
                                            <input class="form-check-input" v-model="collateral_value[v.id].collateral_location" type="radio" value="house_location" :id="`check-${v.id}-house`">
                                            <label class="form-check-label" :for="`check-${i}-house`">
                                                Same as location of house
                                            </label>
                                        </div>
                                    </div>
                                    <div class="list-group-item text-dark">
                                        <div class="form-check">
                                            <input class="form-check-input" v-model="collateral_value[v.id].collateral_location" type="radio" value="business_location" :id="`check-${v.id}-business`">
                                            <label class="form-check-label" :for="`check-${i}-business`">
                                                Same as location of business
                                            </label>
                                        </div>
                                    </div>
                                    <div class="list-group-item text-dark">
                                        <div class="form-check">
                                            <input class="form-check-input" v-model="collateral_value[v.id].collateral_location" type="radio" value="different_location" :id="`check-${v.id}-different`">
                                            <label class="form-check-label" :for="`check-${i}-different`">
                                                Different location
                                            </label>
                                        </div>
                                    </div>
                                    <template v-if="collateral_value[v.id].collateral_location === 'different_location'">
                                        <div class="list-group-item p-0" :id="`${v.id}-collateral-village`">
                                            <locations v-on:village="setVillage" :position="v.id"></locations>
                                            <input type="hidden" v-model="collateral_value[v.id].collateral_village"/>
                                        </div>
                                        <div class="list-group-item text-danger border-danger d-none" :id="`${v.id}-collateral-village-text`">
                                            Location of {{ v.name }} is required
                                        </div>
                                    </template>
                                </template>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-lg-6">
                                <div class="form-row">
                                    <div class="form-group col-md-12">
                                        <label for="business-profit">Business Profit</label>
                                        <div class="input-group mb-3">
                                            <VueNumberFormat id="business-profit" v-model:value="business_profit" class="form-control"
                                                             :class="{ 'is-invalid': v$.business_profit.$errors.length }"></VueNumberFormat>
                                            <div class="input-group-append">
                                                <span class="input-group-text">UGX</span>
                                            </div>
                                            <div class="invalid-feedback" v-for="error of v$.business_profit.$errors" :key="error.$uid">
                                                {{ error.$message }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-row">
                                    <div class="form-group col-md-12">
                                        <label for="profit-frequency">Profit Frequency</label>
                                        <select class="form-control custom-select" id="profit-frequency" v-model="profit_frequency"
                                                :class="{ 'is-invalid': v$.profit_frequency.$errors.length }">
                                            <option>Weekly</option>
                                            <option>Monthly</option>
                                        </select>
                                        <div class="invalid-feedback" v-for="error of v$.profit_frequency.$errors" :key="error.$uid">
                                            {{ error.$message }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <div class="d-flex">
                <a class="btn btn-primary my-auto text-white" href="javascript:void(0)" @click="previous">
                    <i class="mdi mdi-arrow-left"></i> Previous
                </a>
                <a class="btn btn-primary my-auto ml-auto text-white" href="javascript:void(0)" @click="next">
                    Next <i class="mdi mdi-arrow-right"></i>
                </a>
            </div>
        </template>
    </loan-card>
</template>

<script>
    import useVuelidate from '@vuelidate/core';
    import { required, numeric } from '@vuelidate/validators';
    import { createNamespacedHelpers } from 'vuex'
    const { mapActions, mapGetters, mapState } = createNamespacedHelpers('loans');
    import LoanCard from "./LoanCard";
    import NavTabs from "./NavTabs";
    import Constants from "../constants";
    import VueChosen from "./VueChosen";
    import $ from 'jquery';
    import _ from 'lodash';
    import Locations from "./Locations";
    import Firebase from "../firebase";
    import moment from 'moment';

    export default {
        name: "CollateralRequirements",
        components: {Locations, VueChosen, NavTabs, LoanCard},
        data() {
            return {
                time: {start: null, end: null},
                collateral_type: [],
                collateral_locations: [],
                collateral_value: {},
                business_profit: '',
                profit_frequency: ''
            }
        },
        setup () {
            return { v$: useVuelidate() }
        },
        mounted() {
            this.time.start = moment().format();

            Firebase.sendEvent("Collateral requirements tab");
            if (this.requirements)
                this.populate(this.requirements);

            _.each(this.collateral, (collateral) => {
                this.collateral_value[collateral.id] = {
                    id: collateral.id,
                    name: collateral.collateral_name,
                    collateral_value: 0,
                    collateral_location: 'current_location',
                    collateral_village: '',
                    location_required: collateral.location_required
                };
            })
        },
        validations () {
            return {
                business_profit: { required, numeric },
                profit_frequency: { required },
            }
        },
        computed: {
            ...mapState(['collateral', 'locations']),
            ...mapGetters({requirements: Constants.GET_COLLATERAL}),
            collateral_() {
                return {
                    time: this.time,
                    collateral_type: this.collateral_type,
                    collateral_value: this.collateral_value,
                    business_profit: this.business_profit,
                    profit_frequency: this.profit_frequency
                }
            },
            hasCollateral() {
                return _.size(this.collateral_type) > 0;
            },
            final_collateral_value() {
                let values = _.values(this.collateral_value);

                return _.filter(values, (c) => {
                    return _.includes(this.collateral_type, c.id)
                })
            },
            emptyCollateralValue() {
                if (this.hasCollateral) {
                    return _.filter(this.final_collateral_value, (item) => {
                       return item.collateral_value.toString() === '0'
                    });
                }

                return [];
            },
            emptyCollateralVillage() {
                if (this.hasCollateral) {
                    return _.filter(this.final_collateral_value, (item) => {
                       return item.collateral_village.length === 0 && item.collateral_location === 'different_location'
                    });
                }

                return [];
            },
            hasEmptyCollateralValue() {
                return _.size(this.emptyCollateralValue) > 0;
            },
            hasEmptyCollateralVillage() {
                return _.size(this.emptyCollateralVillage) > 0;
            }
        },
        methods: {
            ...mapActions([Constants.SET_POSITION, Constants.SET_COLLATERAL]),
            next() {
                _.each(this.collateral_value, (item) => {
                    $(`#${item.id}-collateral-value`).removeClass('is-invalid');
                    $(`#${item.id}-collateral-village`).removeClass('text-danger border-danger');
                    $(`#${item.id}-collateral-village-label`).removeClass('text-danger');
                    $(`#${item.id}-collateral-village-text`).addClass('d-none');
                });

                if (this.hasEmptyCollateralValue) {
                    _.each(this.emptyCollateralValue, (item) => {
                        $(`#${item.id}-collateral-value`).addClass('is-invalid');
                    });

                    return;
                }

                if (this.hasEmptyCollateralVillage) {
                    _.each(this.emptyCollateralVillage, (item) => {
                        $(`#${item.id}-collateral-village`).addClass('text-danger border-danger');
                        $(`#${item.id}-collateral-village-label`).addClass('text-danger');
                        $(`#${item.id}-collateral-village-text`).removeClass('d-none');
                    });

                    return;
                }

                this.v$.$touch();
                if (this.v$.$error) return;

                this.time.end = moment().format();

                Firebase.sendEvent("Collateral requirements submitted");

                this.SET_COLLATERAL(this.collateral_);
            },
            previous() {
                Firebase.sendEvent("Collateral requirements previous button clicked");
                this.SET_POSITION(1);
            },
            populate(requirements) {
                this.collateral_type = requirements.collateral_type;
                this.collateral_value = requirements.collateral_value;
                this.business_profit = requirements.business_profit;
                this.profit_frequency = requirements.profit_frequency;
            },
            setVillage(payload) {
                this.collateral_value[payload.position]['collateral_village'] = payload.village;
            }
        }
    }
</script>

<style scoped></style>