<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-primary sticky-top py-3" id="navbar">
        <div class="container">
            <a class="navbar-brand" href="javascript:void(0)" id="logo" @click="home">Loans</a>
            <template v-if="authenticated">
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNav">
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {{ user.name }}
                            </a>
                            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <a class="dropdown-item" href="javascript:void(0)" @click="profile">Profile</a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" href="javascript:void(0)" @click="logoutAction">Logout</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </template>
        </div>
    </nav>
</template>

<script>
    import { createNamespacedHelpers } from 'vuex'
    const { mapActions, mapGetters, mapState } = createNamespacedHelpers('auth');
    import { onMounted } from 'vue'
    import $ from 'jquery';
    import Firebase from "../firebase";
    export default {
        name: "NavBar",
        setup () {
            onMounted(() => {
                window.onscroll = function() {scrollFunction()};

                function scrollFunction() {
                    if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
                        $('#navbar').removeClass("py-3");
                    } else {
                        $('#navbar').addClass("py-3");
                    }
                }
            })
        },
        computed: {
            ...mapState({user: 'user'}),
            ...mapGetters({authenticated: 'authenticated'}),
        },
        methods: {
            ...mapActions({logout: 'logout'}),
            logoutAction() {
                this.logout().then(() => {
                    Firebase.sendEvent("Login link clicked");
                    this.$router.push({name: 'login'});
                });
            },
            profile() {
                if (this.$route.name !== 'profile') {
                    Firebase.sendEvent("Profile link clicked");
                    this.$router.push({name: 'profile'});
                }
            },
            home() {
                if (this.$route.name !== 'home') {
                    Firebase.sendEvent("Home link clicked");
                    this.$router.push({name: 'home'});
                }
            }
        }
    }
</script>

<style scoped>
    .navbar {
        box-shadow: 0 2px 2px rgba(0,0,0,.05) !important;
        transition: padding-top .3s linear, padding-bottom .3s linear;
    }
</style>