<template>
    <loan-card>
        <template v-slot:header>
            <nav-tabs></nav-tabs>
        </template>
        <template v-slot:body>
            <div class="container px-0">
                <div class="row">
                    <div class="col-md-12 border p-3 m-3 bg-light" style="border-radius: 4px;">
                        <div class="text-center p-3 mb-4 border-bottom">
                            <h4 class="text-muted"><span>Personal Requirements</span></h4>
                        </div>
                        <div class="form-group">
                            <label>
                                Gender
                            </label>
                            <div class="list-group">
                                <div class="list-group-item">
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input class="custom-control-input" type="radio" name="gender" id="male" value="male" checked v-model="gender">
                                        <label class="custom-control-label" for="male">
                                            Male
                                        </label>
                                    </div>
                                </div>
                                <div class="list-group-item">
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input class="custom-control-input" type="radio" name="gender" id="female" value="female" v-model="gender">
                                        <label class="custom-control-label" for="female">
                                            Female
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>
                                Do you have a Tax Identification Number (TIN)
                            </label>
                            <div class="list-group">
                                <div class="list-group-item">
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input class="custom-control-input" type="radio" name="tin" id="tin-yes" value="1" checked v-model="tin">
                                        <label class="custom-control-label" for="tin-yes">
                                            Yes
                                        </label>
                                    </div>
                                </div>
                                <div class="list-group-item">
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input class="custom-control-input" type="radio" name="tin" id="tin-no" value="0" v-model="tin">
                                        <label class="custom-control-label" for="tin-no">
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>
                                Do you have a currently valid Trading License
                            </label>
                            <div class="list-group">
                                <div class="list-group-item">
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input class="custom-control-input" type="radio" name="license" id="license-yes" value="1" checked v-model="license">
                                        <label class="custom-control-label" for="license-yes">
                                            Yes
                                        </label>
                                    </div>
                                </div>
                                <div class="list-group-item">
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input class="custom-control-input" type="radio" name="license" id="license-no" value="0" v-model="license">
                                        <label class="custom-control-label" for="license-no">
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>
                                Do you already have a bank account
                            </label>
                            <div class="list-group">
                                <div class="list-group-item">
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input class="custom-control-input" type="radio" name="account" id="account-yes" value="1" v-model="account">
                                        <label class="custom-control-label" for="account-yes">
                                            Yes
                                        </label>
                                    </div>
                                </div>
                                <div class="list-group-item">
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input class="custom-control-input" type="radio" name="account" id="account-no" value="0" checked v-model="account">
                                        <label class="custom-control-label" for="account-no">
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <template v-if="account === '1'">
                            <div class="form-group">
                                <label for="account-institutions">At what Institutions</label>
                                <select id="account-institutions" class="form-control custom-select" size="3" v-model="institution" multiple :class="{ 'is-invalid': institutionRequired }">
                                    <option :value="i.id" v-for="(i, p) in institutions" :key="p">{{ i.name }}</option>
                                </select>
                                <div class="invalid-feedback">
                                    Institutions are required
                                </div>
                            </div>
                            <div class="form-group">
                                <label>
                                    Do you have bank statements
                                </label>
                                <div class="list-group">
                                    <div class="list-group-item">
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input class="custom-control-input" type="radio" name="statements" id="statements-yes" value="1" v-model="statements">
                                            <label class="custom-control-label" for="statements-yes">
                                                Yes
                                            </label>
                                        </div>
                                    </div>
                                    <div class="list-group-item">
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input class="custom-control-input" type="radio" name="statements" id="statements-no" value="0" checked v-model="statements">
                                            <label class="custom-control-label" for="statements-no">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group" v-if="statements === '1'">
                                <label for="months">Number of Months Statements held for</label>
                                <div class="input-group mb-3">
                                    <VueNumberFormat id="months" v-model:value="statements_months" class="form-control"
                                                     :class="{ 'is-invalid': statementsMonthsRequired }"></VueNumberFormat>
                                    <div class="input-group-append">
                                        <span class="input-group-text">Months</span>
                                    </div>
                                    <div class="invalid-feedback">
                                        Number of Months Statements held for is required
                                    </div>
                                </div>
                            </div>
                        </template>
                        <div class="form-group">
                            <label>
                                Do you have Guarantors
                            </label>
                            <div class="list-group">
                                <div class="list-group-item">
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input class="custom-control-input" type="radio" name="guarantors" id="guarantors-yes" value="1" v-model="guarantors">
                                        <label class="custom-control-label" for="guarantors-yes">
                                            Yes
                                        </label>
                                    </div>
                                </div>
                                <div class="list-group-item">
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input class="custom-control-input" type="radio" name="guarantors" id="guarantors-no" value="0" checked v-model="guarantors">
                                        <label class="custom-control-label" for="guarantors-no">
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <template v-if="guarantors === '1'">
                            <div class="form-group">
                                <label for="guarantors-number">Number of Guarantors</label>
                                <VueNumberFormat id="guarantors-number" v-model:value="guarantor_number" class="form-control"
                                                 :class="{ 'is-invalid': guarantorNumberRequired }"></VueNumberFormat>
                                <div class="invalid-feedback">
                                    Number of Guarantors is required
                                </div>
                            </div>
                            <div class="form-group">
                                <label>
                                    Is any of the Guarantors a client at a financial institution
                                </label>
                                <div class="list-group">
                                    <div class="list-group-item">
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input class="custom-control-input" type="radio" name="client" id="client-yes" value="1" v-model="client">
                                            <label class="custom-control-label" for="client-yes">
                                                Yes
                                            </label>
                                        </div>
                                    </div>
                                    <div class="list-group-item">
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input class="custom-control-input" type="radio" name="client" id="client-no" value="0" v-model="client">
                                            <label class="custom-control-label" for="client-no">
                                                No, none of them is client at any institution
                                            </label>
                                        </div>
                                    </div>
                                    <div class="list-group-item">
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input class="custom-control-input" type="radio" name="client" id="client-donot-know" value="do not know" v-model="client">
                                            <label class="custom-control-label" for="client-donot-know">
                                                Don’t know if they are client at an institution
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <template v-if="client === '1'">
                                <div class="form-group">
                                    <label for="guarantors-number">Number of Guarantors that are clients at a financial institution</label>
                                    <VueNumberFormat id="guarantors-number-clients" v-model:value="guarantor_number_clients" class="form-control"
                                                     :class="{ 'is-invalid': guarantorNumberClientRequired }"></VueNumberFormat>
                                    <div class="invalid-feedback">
                                        Number of Guarantors is required
                                    </div>
                                </div>
                                <div class="form-group" v-if="guarantor_number_clients > 0">
                                    <label for="guarantors-institutions">Guarantor - Institution Details</label>
                                    <div id="guarantors-institutions">
                                        <div class="input-group mb-1" v-for="(r, i) in guarantor_range" :key="`guarantor-${i}-institutions-${rand}`">
                                            <div class="input-group-prepend">
                                                <label class="input-group-text" :for="`guarantor-${i}-institutions`">Guarantor {{ r + 1 }}</label>
                                            </div>
                                            <select class="form-control custom-select" :id="`guarantor-${i}-institutions`"
                                                    v-model="guarantor_institutions[r + 1].institutions" multiple size="3"
                                                    :class="{'is-invalid': guarantorInstitutionsRequired}">
                                                <option :value="n.id" v-for="(n, p) in institutions" :key="`guarantor-${i}-${p}-institutions`">{{ n.name }}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="text-danger p-2" v-if="guarantorInstitutionsRequired">
                                        Guarantor institution details required
                                    </div>
                                </div>
                            </template>
                        </template>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <div class="text-center" v-if="processing">
                <p class="p-0 m-0">Processing... Please wait!</p>
            </div>
            <div class="d-flex" v-else>
                <a class="btn btn-primary my-auto text-white" href="javascript:void(0)" @click="previous">
                    <i class="mdi mdi-arrow-left"></i> Previous
                </a>
                <a class="btn btn-primary my-auto ml-auto text-white" href="javascript:void(0)" @click="next">
                    <i class="mdi mdi-check"></i> Submit
                </a>
            </div>
        </template>
    </loan-card>
</template>

<script>
    import useVuelidate from '@vuelidate/core';
    import { createNamespacedHelpers } from 'vuex'
    const { mapActions, mapGetters, mapState } = createNamespacedHelpers('loans');
    import LoanCard from "./LoanCard";
    import NavTabs from "./NavTabs";
    import _ from 'lodash';
    import Constants from '../constants';
    import VueChosen from "./VueChosen";
    import Firebase from "../firebase";
    import moment from 'moment';
    export default {
        name: "PersonalRequirements",
        components: {VueChosen, NavTabs, LoanCard},
        data() {
            return {
                time: {start: null, end: null},
                tin: '1',
                gender: 'male',
                license: '1',
                account: '0',
                institution: [],
                statements: '0',
                statements_months: '',
                guarantors: '0',
                guarantor_number: '',
                guarantor_number_clients: '',
                client: '',
                guarantor_institutions: {},
                processing: false
            }
        },
        setup () {
            return { v$: useVuelidate() }
        },
        mounted() {
            this.time.start = moment().format();

            Firebase.sendEvent("Personal requirements tab");
            if (this.requirements)
                this.populate(this.requirements);
        },
        computed: {
            ...mapState(['institutions']),
            rand() {
                return _.random(_.range(50, 100000));
            },
            hasAccount() {
                return this.account === '1';
            },
            institutionRequired() {
                return this.hasAccount & this.institution.length === 0;
            },
            hasStatements() {
                return this.statements === '1';
            },
            statementsMonthsRequired() {
                if (this.hasAccount)
                    return this.hasStatements && _.toNumber(_.trim(this.statements_months)) === 0;

                return false;
            },
            isClient() {
                return this.client === '1';
            },
            guarantorInstitutionsRequired() {
                let values = _.values(this.guarantor_institutions);

                let empty = _.filter(values, (v) => {
                    return _.size(v.institutions) === 0;
                });

                return this.isClient && _.size(empty) !== 0;
            },
            hasGuarantors() {
                return this.guarantors === '1';
            },
            guarantorNumberRequired() {
                return this.hasGuarantors && _.toNumber(_.trim(this.guarantor_number)) === 0;
            },
            guarantorNumberClientRequired() {
                return this.hasGuarantors && _.toNumber(_.trim(this.guarantor_number_clients)) === 0;
            },
            invalid() {
                return this.institutionRequired || this.statementsMonthsRequired ||
                    this.guarantorInstitutionsRequired || this.guarantorNumberRequired;
            },
            ...mapGetters({
                requirements: Constants.GET_PERSONAL,
                collateral: Constants.GET_COLLATERAL,
                loan: Constants.GET_LOAN
            }),
            personal() {
                return {
                    gender: this.gender,
                    time: this.time,
                    tin: this.tin,
                    license: this.license,
                    account: this.account,
                    institution: this.institution,
                    statements: this.statements,
                    statements_months: this.statements_months,
                    guarantors: this.guarantors,
                    guarantor_number: this.guarantor_number,
                    guarantor_number_clients: this.guarantor_number_clients,
                    client: this.client,
                    guarantor_institutions: this.guarantor_institutions,
                }
            },
            guarantor_range() {
                return _.range(this.guarantor_number_clients);
            }
        },
        methods: {
            ...mapActions([Constants.SET_POSITION, Constants.SET_PERSONAL, Constants.FILTER_PRODUCTS]),
            next() {
                this.v$.$touch();
                if (this.v$.$error || this.invalid) return;

                this.time.end = moment().format();

                if (!this.processing) {
                    this.processing = true;
                    this.FILTER_PRODUCTS({personal: this.personal, loan: this.loan, collateral: this.collateral})
                        .then(() => {
                            this.processing = false;
                            Firebase.sendEvent("Personal requirements submitted");
                            this.SET_PERSONAL(this.personal);
                        })
                        .catch((error) => {
                            this.processing = false;
                            console.log(error);
                            console.log(JSON.stringify(error));
                        });
                }
            },
            previous() {
                Firebase.sendEvent("Personal requirements previous button clicked");
                this.SET_POSITION(2);
            },
            populate(requirements) {
                this.tin = requirements.tin;
                this.gender = requirements.gender;
                this.license = requirements.license;
                this.account = requirements.account;
                this.institution = requirements.institution;
                this.statements = requirements.statements;
                this.statements_months = requirements.statements_months;
                this.guarantors = requirements.guarantors;
                this.guarantor_number = requirements.guarantor_number;
                this.guarantor_number_clients = requirements.guarantor_number_clients;
                this.client = requirements.client;
                this.guarantor_institutions = requirements.guarantor_institutions;
            }
        },
        watch: {
            guarantor_number_clients() {
                if (this.guarantor_number_clients > 0) {
                    this.guarantor_institutions = {};
                    _.each(this.guarantor_range, (r) => {
                        this.guarantor_institutions[r + 1] = {institutions: []};
                    })
                }
            }
        }
    }
</script>

<style scoped>

</style>