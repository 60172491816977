import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import Profile from '../views/Profile.vue'
import middlewarePipeline from "../middleware/middlewarePipeline";
import store from '../store/index';
import auth from "../middleware/auth";
import guest from "../middleware/guest";

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            pageTitle: 'Loans - Home',
            middleware: [auth],
        },
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
            pageTitle: 'Loans - Profile',
            middleware: [auth],
        },
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            pageTitle: 'Loans - Login',
            middleware: [guest],
        },
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        meta: {
            pageTitle: 'Loans - Register',
            middleware: [guest],
        },
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {

    // For Middleware
    if (!to.meta.middleware) {
        return next();
    }
    const middleware = to.meta.middleware;

    const context = {
        to,
        from,
        next,
        store,
    };

    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1),
    });
});

export default router
