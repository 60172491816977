<template>
    <div class="container">
        <ul class="nav nav-tabs card-header-tabs">
            <li class="nav-item">
                <a class="nav-link" :class="{active: position === 1, previous: position > 1}" href="javascript:void(0)" @click="loan" title="Loan">
                    <i class="mdi mdi-account-cash"></i> <p class="m-0">Loan</p>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" :class="{active: position === 2, previous: position > 2}" href="javascript:void(0)" @click="collateral" title="Collateral">
                    <i class="mdi mdi-briefcase-plus"></i> <p class="m-0">Collateral</p>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" :class="{active: position === 3, previous: position > 3}" href="javascript:void(0)" @click="personal" title="Personal">
                    <i class="mdi mdi-account-question"></i> <p class="m-0">Personal</p>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" :class="{active: position === 4}" href="javascript:void(0)" title="Eligible Products">
                    <i class="mdi mdi-format-list-text"></i> <p class="m-0">Eligible Products</p>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
    import { createNamespacedHelpers } from 'vuex'
    import Firebase from "../firebase";
    const { mapActions, mapGetters } = createNamespacedHelpers('loans');
    export default {
        name: "NavTabs",
        computed: {
            ...mapGetters(['GET_POSITION']),
            position() {
                return this.GET_POSITION;
            }
        },
        methods: {
            ...mapActions(['SET_POSITION']),
            loan() {
                if (this.position > 1) {
                    Firebase.sendEvent("Loan tab button clicked");
                    this.SET_POSITION(1);
                }
            },
            collateral() {
                if (this.position > 2) {
                    Firebase.sendEvent("Collateral tab button clicked");
                    this.SET_POSITION(2);
                }
            },
            personal() {
                if (this.position > 3) {
                    Firebase.sendEvent("Personal tab button clicked");
                    this.SET_POSITION(3);
                }
            }
        }
    }
</script>

<style scoped>
    .nav-pills .nav-link.active {
        color: #007bff;
        background-color: #FFFFFF;
    }

    .nav-link {
        /*color: #FFFFFF !important;*/
        border: unset!important;
        color: rgba(255,255,255,.5);
    }

    .nav-link.previous {
        color: #FFFFFF;
    }

    .nav-link.active {
        color: #007bff !important;
        box-shadow: -2px -4px 5px -3px #666;
    }

    .nav-link:hover {
        border: unset!important;
    }

    @media (max-width: 576px) {
        .nav-link p {
            display: none;
        }
    }
</style>