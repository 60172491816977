<template>
    <div class="card main h-100">
        <div class="card-header text-center border-0">
            <slot name="header"></slot>
        </div>
        <div class="card-body h-100">
            <slot name="body"></slot>
        </div>
        <div class="card-footer">
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script>
    import { createNamespacedHelpers } from 'vuex'
    import Constants from "../constants";
    const { mapActions, mapState, mapGetters } = createNamespacedHelpers('loans');
    export default {
        name: "LoanCard",
        created() {
            this.fetchInstitutions();
            this.fetchCollateral();
            this.fetchLoanFees();

            const collateralType = this.collateralRequirements && this.collateralRequirements.collateral_type ? this.collateralRequirements.collateral_type : [];
            this.fetchCollateralFees({collateral_type: collateralType});

            this.fetchLocations();
            this.fetchCounties();
        },
        computed: {
            ...mapState(['collateral', 'institutions', 'fees', 'collateralFees', 'locations', 'counties']),
            ...mapGetters({collateralRequirements: Constants.GET_COLLATERAL})
        },
        methods: {
            ...mapActions({
                fetchInstitutions: Constants.SET_INSTITUTION_LIST,
                fetchCollateral: Constants.SET_COLLATERAL_LIST,
                fetchLoanFees: Constants.SET_LOAN_FEES,
                fetchCollateralFees: Constants.SET_COLLATERAL_FEES,
                fetchLocations: Constants.SET_LOCATIONS,
                fetchCounties: Constants.SET_COUNTIES
            })
        }
    }
</script>

<style scoped>
    .card.main {
        /*height: calc(99.9vh - 3.45rem) !important;*/
        /*height: calc(99.9vh) !important;*/
        border: unset !important;
    }

    /*.card.main .card-body {*/
    /*overflow-y: auto !important;*/
    /*overflow-x: hidden !important;*/
    /*}*/

    .card.main .card-header {
        border-radius: unset !important;
        padding-top: 5.45rem;

        background: linear-gradient(to right, rgba(0, 123, 255, 0.5) 0, rgba(102, 102, 102, 0.5) 100%), url('../assets/images/banner.jpg');
        -webkit-background-size: cover;
        -moz-background-size:  cover;
        -o-background-size: cover;
        background-size: cover;
    }
</style>