<template>
    <form class="form-signin">
        <div class="text-center">
            <img class="mb-4 img-thumbnail" src="@/assets/logo.png" alt="" width="72"
                 height="72">
            <h1 class="h3 mb-3 font-weight-normal">Register</h1>
        </div>
        <div v-if="registerError">
            <errors :errors="registerError" v-on:close="closeErrors"></errors>
        </div>
        <div class="form-group">
            <input type="text" v-model="name" id="name" class="form-control" :class="{ 'is-invalid': v$.name.$errors.length }" placeholder="Name" autofocus="">
            <div class="invalid-feedback" v-for="error of v$.name.$errors" :key="error.$uid">
                {{ error.$message }}
            </div>
        </div>
        <div class="form-group">
            <input type="tel" v-model="phone" id="phone" class="form-control" :class="{ 'is-invalid': v$.phone.$errors.length }" placeholder="Phone Number" autofocus="">
            <div class="invalid-feedback" v-for="error of v$.phone.$errors" :key="error.$uid">
                {{ error.$message }}
            </div>
        </div>
        <div class="form-group">
            <input type="email" v-model="email" id="email" class="form-control" :class="{ 'is-invalid': v$.email.$errors.length }" placeholder="Email address" autofocus="">
            <div class="invalid-feedback" v-for="error of v$.email.$errors" :key="error.$uid">
                {{ error.$message }}
            </div>
        </div>
        <div class="form-group">
            <input type="password" v-model="password" id="password" class="form-control" :class="{ 'is-invalid': v$.password.$errors.length }" placeholder="Password">
            <div class="invalid-feedback" v-for="error of v$.password.$errors" :key="error.$uid">
                {{ error.$message }}
            </div>
        </div>
        <div class="form-group">
            <input type="password" v-model="password_confirmation" id="password_confirmation" class="form-control" :class="{ 'is-invalid': v$.password_confirmation.$errors.length }" placeholder="Confirm Password" required="">
            <div class="invalid-feedback" v-for="error of v$.password_confirmation.$errors" :key="error.$uid">
                {{ error.$message }}
            </div>
        </div>
        <a @click="auth" href="javascript:void(0)" class="btn btn-lg btn-primary btn-block">
            <template v-if="processing">Processing...</template>
            <template v-else>Sign up</template>
        </a>
        <a @click="login" href="javascript:void(0)" class="btn btn-lg btn-link btn-block">Already registered? Login</a>
    </form>
</template>
<script>
    import { createNamespacedHelpers } from 'vuex'
    const { mapActions } = createNamespacedHelpers('auth');
    import useVuelidate from '@vuelidate/core';
    import { required, email, sameAs } from '@vuelidate/validators';
    import Errors from "../components/Errors";
    import _ from 'lodash';
    import Firebase from "../firebase";
    export default {
        name: 'Register',
        components: {Errors},
        data(){
            return {
                name: '',
                phone: '',
                email: '',
                password: '',
                password_confirmation: '',
                registerError: [],
                processing: false
            }
        },
        setup () {
            return { v$: useVuelidate() }
        },
        validations () {
            return {
                name: { required },
                phone: { required },
                email: { required, email },
                password: { required },
                password_confirmation: { required, sameAsPassword: sameAs(this.password) },
            }
        },
        methods: {
            ...mapActions({register: 'register'}),
            closeErrors() {
                this.registerError = [];
            },
            login() {
                this.$router.push({name: 'login'});
            },
            auth() {
                this.closeErrors();
                this.v$.$touch();

                if (this.v$.$error) return;

                Firebase.sendEvent("Register button clicked");

                if (!this.processing) {
                    this.processing = true;
                    this.register({
                        name: this.name,
                        phone_number: this.phone,
                        email: this.email,
                        password: this.password,
                        password_confirmation: this.password_confirmation
                    })
                        .then(() => {
                            this.processing = false;
                            this.$router.push({name: 'home'});
                        })
                        .catch((error) => {
                            this.processing = false;
                            if (typeof error.response.data === 'object') {
                                this.registerError = _.flatten(_.toArray(error.response.data.errors));
                            } else {
                                this.registerError = ['Something went wrong. Please try again.'];
                            }
                        });
                }
            }
        }
    }
</script>
<style scoped>

</style>