<template>
    <form class="form-signin">
        <div class="text-center">
            <img class="mb-4 img-thumbnail" src="@/assets/logo.png" alt="" width="72"
                 height="72">
            <h1 class="h3 mb-3 font-weight-normal">Sign in</h1>
        </div>
        <div v-if="loginError">
            <errors :errors="loginError" v-on:close="closeErrors"></errors>
        </div>
        <div class="form-group">
            <input type="tel" v-model="phone_number" id="email" class="form-control" :class="{ 'is-invalid': v$.phone_number.$errors.length }" placeholder="Phone Number" autofocus="">
            <div class="invalid-feedback" v-for="error of v$.phone_number.$errors" :key="error.$uid">
                {{ error.$message }}
            </div>
        </div>
        <div class="form-group">
            <input type="password" v-model="password" id="password" class="form-control" :class="{ 'is-invalid': v$.password.$errors.length }" placeholder="Password">
            <div class="invalid-feedback" v-for="error of v$.password.$errors" :key="error.$uid">
                {{ error.$message }}
            </div>
        </div>
        <a @click="auth" href="javascript:void(0)" class="btn btn-lg btn-primary btn-block">
            <template v-if="processing">Processing...</template>
            <template v-else>Sign in</template>
        </a>
        <a @click="register" href="javascript:void(0)" class="btn btn-lg btn-link btn-block">New here? Register</a>
    </form>
</template>
<script>
    import { createNamespacedHelpers } from 'vuex'
    const { mapActions } = createNamespacedHelpers('auth');
    import useVuelidate from '@vuelidate/core';
    import { required } from '@vuelidate/validators';
    import Errors from "../components/Errors";
    import _ from 'lodash';
    import Firebase from '../firebase'
    export default {
        name: 'Login',
        components: {Errors},
        data(){
            return {
                phone_number: '',
                password: '',
                loginError: [],
                processing: false
            }
        },
        setup () {
            return { v$: useVuelidate() }
        },
        validations () {
            return {
                phone_number: { required},
                password: { required },
            }
        },
        methods: {
            ...mapActions({login: 'login'}),
            closeErrors() {
                this.loginError = [];
            },
            register() {
                this.$router.push({name: 'register'});
            },
            auth() {
                this.closeErrors();
                this.v$.$touch();

                if (this.v$.$error) return;

                Firebase.sendEvent("Login button clicked");

                if (!this.processing) {
                    this.processing = true;
                    this.login({phone_number: this.phone_number, password: this.password})
                        .then(() => {
                            this.processing = false;
                            Firebase.sendEvent("Login successful");
                            this.$router.push({name: 'home'});
                        })
                        .catch((error) => {
                            this.processing = false;
                            if (typeof error.response.data === 'object') {
                                this.loginError = _.flatten(_.toArray(error.response.data.errors));
                            } else {
                                this.loginError = ['Something went wrong. Please try again.'];
                            }
                        });
                }
            }
        }
    }
</script>
<style scoped>

</style>