<template>
    <div>
        <loan-requirements v-if="position === 1"></loan-requirements>
        <collateral-requirements v-else-if="position === 2"></collateral-requirements>
        <personal-requirements v-else-if="position === 3"></personal-requirements>
        <recommendations v-else></recommendations>
    </div>
</template>

<script>
    // @ is an alias to /src
    // import HelloWorld from '@/components/HelloWorld.vue'

    import CollateralRequirements from "../components/CollateralRequirements";
    import PersonalRequirements from "../components/PersonalRequirements";
    import { createNamespacedHelpers } from 'vuex'
    const { mapGetters } = createNamespacedHelpers('loans');
    import LoanRequirements from "../components/LoanRequirements";
    import Recommendations from "../components/Recommendations";
    export default {
        name: 'Home',
        components: {Recommendations, LoanRequirements, PersonalRequirements, CollateralRequirements},
        data() {
            return {
                maximum: 4
            }
        },
        computed: {
            ...mapGetters(['GET_POSITION']),
            position() {
                return this.GET_POSITION;
            }
        }
    }
</script>
<style scoped>

</style>
