<template>
    <div class="card main">
        <div class="card-header text-center border-0"></div>
        <div class="card-body border-0 p-0">
            <div class="text-center" style="position: relative; top: -70px;">
                <img src="@/assets/images/avatar.jpg" style="width: 100px; border: 3px solid #FFFFFF;" class="rounded-circle"/>
                <div class="list-group mt-4">
                    <div class="list-group-item d-flex">
                        <label class="my-auto">Name:</label>
                        <span class="ml-auto my-auto">{{ user.name }}</span>
                    </div>
                    <div class="list-group-item d-flex">
                        <label class="my-auto">Email:</label>
                        <span class="ml-auto my-auto">{{ user.email }}</span>
                    </div>
                    <div class="list-group-item d-flex">
                        <label class="my-auto">Phone:</label>
                        <span class="ml-auto my-auto">{{ user.phone_number }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { createNamespacedHelpers } from 'vuex'
    const { mapState } = createNamespacedHelpers('auth');
    export default {
        name: "Profile",
        computed: {
            ...mapState({user: 'user'})
        }
    }
</script>

<style scoped>
    .card.main {
        /*height: calc(99.9vh - 3.45rem) !important;*/
        /*height: calc(99.9vh) !important;*/
        border: unset !important;
    }

    /*.card.main .card-body {*/
    /*overflow-y: auto !important;*/
    /*overflow-x: hidden !important;*/
    /*}*/

    .card.main .card-header {
        border-radius: unset !important;
        padding-top: 5.45rem;

        background: linear-gradient(to right, rgba(0, 123, 255, 0.5) 0, rgba(102, 102, 102, 0.5) 100%), url('../assets/images/banner.jpg');
        -webkit-background-size: cover;
        -moz-background-size:  cover;
        -o-background-size: cover;
        background-size: cover;
    }
</style>