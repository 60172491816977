export default {
    SET_TOKEN: 'SET_TOKEN',
    SET_USER: 'SET_USER',
    SET_POSITION: 'SET_POSITION',
    SET_LOAN: 'SET_LOAN',
    SET_COLLATERAL: 'SET_COLLATERAL',
    SET_PERSONAL: 'SET_PERSONAL',
    SET_COLLATERAL_LIST: 'SET_COLLATERAL_LIST',
    SET_INSTITUTION_LIST: 'SET_INSTITUTION_LIST',
    GET_COLLATERAL: 'GET_COLLATERAL',
    GET_PERSONAL: 'GET_PERSONAL',
    GET_LOAN: 'GET_LOAN',
    SET_PRODUCTS: 'SET_PRODUCTS',
    FILTER_PRODUCTS: 'FILTER_PRODUCTS',
    SET_LOAN_FEES: 'SET_LOAN_FEES',
    SET_COLLATERAL_FEES: 'SET_COLLATERAL_FEES',
    SET_LOCATIONS: 'SET_LOCATIONS',
    SET_COUNTIES: 'SET_COUNTIES'
}