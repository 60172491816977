<template>
    <div class="list-group" :class="{'border-success': selected}">
        <div class="list-group-item active">
            <div class="custom-control custom-checkbox custom-control-inline">
                <input class="custom-control-input" type="checkbox" name="selected" id="selected" v-model="selected">
                <label class="custom-control-label" for="selected">
                    {{ product.commercial_name }} [{{ product.owner_institution.name }}] <span class="badge bg-primary" v-if="selected"><i class="mdi mdi-check-circle"></i> Selected</span>
                </label>
            </div>
        </div>
        <div class="list-group-item d-flex">
            <span class="my-auto font-weight-bold">Total Cost of the Loan:</span>
            <span class="my-auto ml-auto">UGX {{ product.tcc_commas }}</span>
        </div>
        <div class="list-group-item d-flex">
            <span class="my-auto font-weight-bold">Installment Amount:</span>
            <span class="my-auto ml-auto">UGX {{ product.installment_amount }}</span>
        </div>
        <div class="list-group-item d-flex">
            <span class="my-auto font-weight-bold">Interest Rate:</span>
            <span class="my-auto ml-auto">% {{ product.interest_rate_percentage }} [{{ product.interest_rate_type }}]</span>
        </div>
        <div class="list-group-item bg-light" v-if="product.loan_fees.length > 0">
            Loan Fees
        </div>
        <template v-for="(fee, i) in product.loan_fees" :key="`loan-${i}-fee-applicable-td`">
            <div class="list-group-item d-flex">
                <span class="my-auto font-weight-bold">{{ fee.fee_name }}:</span>
                <span class="my-auto ml-auto">{{ fee_amount(product.loan_fees, fee.fee_name) }}</span>
            </div>
        </template>
        <div class="list-group-item bg-light" v-if="product.collateral_fees.length > 0">
            Collateral Fees
        </div>
        <template v-for="(fee, i) in product.collateral_fees" :key="`loan-${i}-collateral-fee-applicable-td`">
            <div class="list-group-item d-flex">
                <span class="my-auto font-weight-bold">{{ fee.collateral_type_fee_name }}:</span>
                <span class="my-auto ml-auto">{{ collateral_fee_amount(product.collateral_fees, fee.collateral_type_fee_name + ' ' + fee.collateral_type_id) }}</span>
            </div>
        </template>
        <div class="list-group-item d-flex" v-if="product.decision_turnaround_time_in_days">
            <span class="my-auto font-weight-bold">Decision Turnaround time:</span>
            <span class="my-auto ml-auto">{{ product.decision_turnaround_time_in_days }} days</span>
        </div>
        <template v-if="product.selected_collateral.branch && product.selected_collateral.name">
            <div class="list-group-item bg-light">
                Nearest Branch to {{ product.selected_collateral.name }}
            </div>
            <div class="list-group-item d-flex">
                <span class="my-auto font-weight-bold">Name:</span>
                <span class="my-auto ml-auto">{{ product.selected_collateral.branch.name }}</span>
            </div>
            <div class="list-group-item d-flex">
                <span class="my-auto font-weight-bold">District:</span>
                <span class="my-auto ml-auto">{{ product.selected_collateral.branch.district }}</span>
            </div>
            <div class="list-group-item d-flex">
                <span class="my-auto font-weight-bold">County:</span>
                <span class="my-auto ml-auto">{{ product.selected_collateral.branch.county }}</span>
            </div>
            <div class="list-group-item d-flex">
                <span class="my-auto font-weight-bold">Sub County:</span>
                <span class="my-auto ml-auto">{{ product.selected_collateral.branch.sub_county }}</span>
            </div>
            <div class="list-group-item d-flex">
                <span class="my-auto font-weight-bold">Parish:</span>
                <span class="my-auto ml-auto">{{ product.selected_collateral.branch.parish }}</span>
            </div>
            <div class="list-group-item d-flex">
                <span class="my-auto font-weight-bold">Village:</span>
                <span class="my-auto ml-auto">{{ product.selected_collateral.branch.village }}</span>
            </div>
            <div class="list-group-item d-flex">
                <span class="my-auto font-weight-bold">Distance:</span>
                <span class="my-auto ml-auto">{{ product.selected_collateral.branch.distance }} {{ product.selected_collateral.branch.distance_units }}</span>
            </div>
        </template>
    </div>
</template>

<script>
    import lo from 'lodash';
    export default {
        name: "ComparedProduct",
        data() {
            return {
                selected: false
            }
        },
        props: {
            product: {
                type: Object,
                required: true
            }
        },
        mounted() {
            this.$emit('details', {product_id: this.product.id, details: this.details});
        },
        computed: {
            details() {
                let details = [
                    {title: 'name', value: `${this.product.commercial_name} [${this.product.owner_institution.name}]`, header: true, array: false, selected: this.selected},
                    {title: 'Total Cost of the Loan', value: `UGX ${this.product.tcc_commas}`, header: false, array: false},
                    {title: 'Installment Amount', value: `UGX ${this.product.installment_amount}`, header: false, array: false},
                    {title: 'Interest Rate', value: `% ${ this.product.interest_rate_percentage } [${ this.product.interest_rate_type }]`, header: false, array: false},
                ];

                if (this.product.loan_fees.length > 0) {
                    let values = lo.map(this.product.loan_fees, (fee) => {
                        return {title: fee.fee_name, value: this.fee_amount(this.product.loan_fees, fee.fee_name)}
                    });

                    details.push({title: 'Loan Fees', value: values, header: false, array: true});
                }

                if (this.product.collateral_fees.length > 0) {
                    let values = lo.map(this.product.collateral_fees, (fee) => {
                        return {title: fee.collateral_type_fee_name, value: this.collateral_fee_amount(this.product.collateral_fees, fee.collateral_type_fee_name + ' ' + fee.collateral_type_id)}
                    });

                    details.push({title: 'Collateral Fees', value: values, header: false, array: true});
                }

                if (this.product.decision_turnaround_time_in_days) {
                    details.push({
                        title: 'Decision Turnaround time',
                        value: `${ this.product.decision_turnaround_time_in_days } days`,
                        header: false,
                        array: false
                    });
                }

                if (this.product.selected_collateral.branch && this.product.selected_collateral.name) {
                    let values = [
                        {title: 'District', value: this.product.selected_collateral.branch.district},
                        {title: 'County', value: this.product.selected_collateral.branch.county},
                        {title: 'Sub County', value: this.product.selected_collateral.branch.sub_county},
                        {title: 'Parish', value: this.product.selected_collateral.branch.parish},
                        {title: 'Village', value: this.product.selected_collateral.branch.village},
                        {title: 'Distance', value: `${this.product.selected_collateral.branch.distance} ${this.product.selected_collateral.branch.distance_units}`},
                    ];

                    details.push({
                        title: `Nearest Branch to ${ this.product.selected_collateral.name }`,
                        value: values,
                        header: false,
                        array: true
                    });
                }

                return details;
            }
        },
        methods: {
            commas(num) {
                return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
            },
            fee_amount(fees, fee_name) {
                let fee = fees.find((item) => item.fee_name === fee_name);

                if (fee) {
                    if (fee.fee_amount_deducted)
                        return `Sh. ${this.commas(fee.fee_amount_deducted)}`;

                    if (fee.fee_amount_loan_percentage)
                        return `${fee.fee_amount_loan_percentage} %`;
                }

                return '';
            },
            collateral_fee_amount(fees, fee_name) {
                let fee = fees.find((item) => item.collateral_type_fee_name + ' ' + item.collateral_type_id === fee_name);

                if (fee) {
                    if (fee.collateral_fee_amount_deducted)
                        return `Sh. ${this.commas(fee.collateral_fee_amount_deducted)}`;

                    if (fee.collateral_fee_amount_loan_percentage)
                        return `${fee.collateral_fee_amount_loan_percentage} %`;
                }

                return '';
            }
        },
        watch: {
            selected() {
                this.$emit('details-updated', {product_id: this.product.id, details: this.details});
            }
        }
    }
</script>

<style scoped>

</style>