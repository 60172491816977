<template>
    <div class="list-group list-group-flush">
        <div class="list-group-item p-1">
            <div class="input-group m-0">
                <div class="input-group-append">
                    <span class="input-group-text">District</span>
                </div>
                <select class="form-control" v-model="district">
                    <option></option>
                    <option v-for="(d, indx) in locations" :value="d.id" :key="`district-${indx}`">{{ d.name }}</option>
                </select>
            </div>
        </div>
        <div class="list-group-item p-1">
            <div class="input-group m-0">
                <div class="input-group-append">
                    <span class="input-group-text">County</span>
                </div>
                <select class="form-control" v-model="county">
                    <option></option>
                    <option v-for="(d, indx) in counties" :value="d.id" :key="`county-${indx}`">{{ d.name }}</option>
                </select>
            </div>
        </div>
        <div class="list-group-item p-1">
            <div class="input-group m-0">
                <div class="input-group-append">
                    <span class="input-group-text">Sub County</span>
                </div>
                <select class="form-control" v-model="subCounty">
                    <option></option>
                    <option v-for="(d, indx) in sub_counties" :value="d.id" :key="`subCounty-${indx}`">{{ d.name }}</option>
                </select>
            </div>
        </div>
        <div class="list-group-item p-1">
            <div class="input-group m-0">
                <div class="input-group-append">
                    <span class="input-group-text">Parish</span>
                </div>
                <select class="form-control" v-model="parish">
                    <option></option>
                    <option v-for="(d, indx) in parishes" :value="d.id" :key="`parish-${indx}`">{{ d.name }}</option>
                </select>
            </div>
        </div>
        <div class="list-group-item p-1 border-bottom-0">
            <div class="input-group m-0">
                <div class="input-group-append">
                    <span class="input-group-text">Village</span>
                </div>
                <select class="form-control" v-model="village">
                    <option></option>
                    <option v-for="(d, indx) in villages" :value="d.id" :key="`village-${indx}`">{{ d.name }}</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import {createNamespacedHelpers} from 'vuex'

    const {mapState} = createNamespacedHelpers('loans');
    export default {
        name: "Locations",
        data() {
            return {
                district: '',
                county: '',
                subCounty: '',
                parish: '',
                village: ''
            }
        },
        props: {
            position: {
                type: String,
                required: true
            }
        },
        computed: {
            ...mapState(['locations']),
            district_() {
                if (this.district) {
                    return _.find(this.locations, (d) => {
                        return this.district.toString() === d.id.toString()
                    })
                }

                return null;
            },
            counties() {
                if (this.district_) {
                    return this.district_.counties;
                }

                return [];
            },
            county_() {
                if (this.county) {
                    return _.find(this.counties, (d) => {
                        return this.county.toString() === d.id.toString()
                    })
                }

                return null;
            },
            sub_counties() {
                if (this.county_) {
                    return this.county_.sub_counties;
                }

                return [];
            },
            sub_county_() {
                if (this.subCounty) {
                    return _.find(this.sub_counties, (d) => {
                        return this.subCounty.toString() === d.id.toString()
                    })
                }

                return null;
            },
            parishes() {
                if (this.sub_county_) {
                    return this.sub_county_.parishes;
                }

                return [];
            },
            parish_() {
                if (this.parish) {
                    return _.find(this.parishes, (d) => {
                        return this.parish.toString() === d.id.toString()
                    })
                }

                return null;
            },
            villages() {
                if (this.parish_) {
                    return this.parish_.villages;
                }

                return [];
            }
        },
        watch: {
            district() {
                this.county = '';
                this.subCounty = '';
                this.parish = '';
                this.village = '';
            },
            county() {
                this.subCounty = '';
                this.parish = '';
                this.village = '';
            },
            subCounty() {
                this.parish = '';
                this.village = '';
            },
            parish() {
                this.village = '';
            },
            village() {
                this.$emit('village', {position: this.position, village: this.village})
            }
        }
    }
</script>

<style scoped>

</style>