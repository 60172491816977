<template>
    <swiper
            :slidesPerView="2"
            :spaceBetween="30"
            :centeredSlides="false"
            :pagination="{
      clickable: true,
    }"
            :modules="modules"
            class="mySwiper"
    >
        <swiper-slide v-for="(product, key) in products" :key="`${key}-product`">
            <compared-product :product="product" v-on:details="populate" v-on:details-updated="update"></compared-product>
        </swiper-slide>
    </swiper>
</template>

<script>
    import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
    import "swiper/swiper-bundle.css";
    import { Pagination } from "swiper";
    import ComparedProduct from "./ComparedProduct";
    import lo from 'lodash';
    import { createNamespacedHelpers } from 'vuex'
    const { mapGetters } = createNamespacedHelpers('loans');
    import Api from '../api';
    import Constants from "../constants";
    export default {
        name: "Comparison",
        components: {ComparedProduct, Swiper, SwiperSlide},
        data() {
            return {
                details: [],
                comparison: null
            }
        },
        setup() {
            return {
                modules: [Pagination],
            };
        },
        props: {
            products: {
                type: Array,
                required: true
            }
        },
        computed: {
            ...mapGetters({
                loan: Constants.GET_LOAN
            }),
            data() {
                return lo.map(this.details, (product) => {
                    return product.details
                })
            }
        },
        methods: {
            populate(details) {
                if (this.details.length > 0) {
                    let product = this.details.find((item) => item.product_id.toString() === details.product_id.toString());
                    if (!product) {
                        this.details.push(details);
                    }
                } else {
                    this.details.push(details);
                }

                if (this.details.length === this.products.length) {
                    this.pushToServer();
                }
            },
            pushToServer() {
                Api.compare({respondent_id: this.loan.respondent_id, data: this.data})
                    .then((response) => {
                        this.comparison = response.data;
                    })
                    .catch(() => {});
            },
            update(details) {
                if (this.details.length > 0) {
                    let product = this.details.find((item) => item.product_id.toString() === details.product_id.toString());
                    if (product) {
                        let filtered = lo.reject(this.details, (item) => {
                            return item.product_id.toString() === details.product_id.toString();
                        });

                        filtered.push(details);
                        this.details = filtered;

                        this.updateComparison();
                    }
                }
            },
            updateComparison() {
                if (this.comparison)
                    Api.updateComparison(this.comparison.id, {data: this.data})
                        .then((response) => {
                            this.comparison = response.data;
                        })
                        .catch(() => {});
            }
        }
    }
</script>

<style scoped>

</style>