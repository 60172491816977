<template>
    <nav-bar></nav-bar>
    <router-view></router-view>
</template>

<script>
    import NavBar from "./components/NavBar";
    export default {
        name: 'App',
        components: {NavBar}
    }
</script>

<style>
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
    }

    .shadow {
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.05) !important;
    }
</style>
