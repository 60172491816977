import {createStore} from 'vuex'
import loans from "./loans";
import auth from "./auth";

export default createStore({
    state: {},
    mutations: {},
    getters: {},
    actions: {},
    modules: {
        auth: auth,
        loans: loans,
    }
})
