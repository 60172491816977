<template>
    <!-- Form Errors -->
    <div class="alert alert-danger alert-dismissible fade show" role="alert"
         v-if="errors.length > 0" style="border-radius: 4px;">
        <ul class="m-0">
            <li v-for="error in errors">
                {{ error }}
            </li>
        </ul>
        <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</template>

<script>
    export default {
        name: "Errors",
        props: {
            errors: {
                type: Array
            }
        },
        methods: {
            close() {
                this.$emit('close');
            }
        }
    }
</script>

<style scoped>

</style>