import axios from "axios";

export default {
    login(credentials) {
        return axios.post('login', credentials)
    },
    register(credentials) {
        return axios.post('register', credentials)
    },
    me() {
        return axios.get('me');
    },
    update(params) {
        return axios.post('me/update', params);
    },
    logout() {
        return axios.post('me/logout');
    },
    institutions() {
        return axios.get('me/institutions');
    },
    locations() {
        return axios.get('me/locations');
    },
    counties() {
        return axios.get('me/counties');
    },
    loanFees() {
        return axios.get('me/loan-fees');
    },
    collateralFees(params) {
        return axios.get('me/collateral-fees', { params });
    },
    collateral() {
        return axios.get('me/collateral');
    },
    filter(attributes) {
        return axios.post('me/filter', attributes);
    },
    compare(attributes) {
        return axios.post('me/compare', attributes);
    },
    updateComparison(comparison, attributes) {
        return axios.post(`me/compare/${comparison}/update`, attributes);
    }
}