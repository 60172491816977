<template>
    <loan-card>
        <template v-slot:header>
            <nav-tabs></nav-tabs>
        </template>
        <template v-slot:body>
            <div class="container px-0">
                <div class="row">
                    <div class="col-md-12 border p-3 m-3 bg-light" style="border-radius: 4px;">
                        <div class="text-center p-3 mb-4 border-bottom">
                            <h4 class="text-muted"><span>Desired Loan</span></h4>
                        </div>
                        <div class="form-group">
                            <label for="respondent-id">Respondent ID</label>
                            <input class="form-control" v-model="respondent_id"
                                   :class="{ 'is-invalid': v$.respondent_id.$errors.length }" type="text" id="respondent-id"/>
                            <div class="invalid-feedback" v-for="error of v$.respondent_id.$errors" :key="error.$uid">
                                {{ error.$message }}
                            </div>
                        </div>

	                    <div class="form-group">
		                    <label>
			                    Are you looking for a group loan or individual loan?
		                    </label>
		                    <div class="list-group">
			                    <div class="list-group-item">
				                    <div class="custom-control custom-radio custom-control-inline">
					                    <input class="custom-control-input" type="radio" name="loan_type" id="individual"
					                           value="individual" v-model="loan_type">
					                    <label class="custom-control-label" for="individual">
						                    Individual Loan
					                    </label>
				                    </div>
			                    </div>
			                    <div class="list-group-item">
				                    <div class="custom-control custom-radio custom-control-inline">
					                    <input class="custom-control-input" type="radio" name="loan_type" id="group"
					                           value="group" v-model="loan_type">
					                    <label class="custom-control-label" for="group">
						                    Group Loan
					                    </label>
				                    </div>
			                    </div>
			                    <div class="list-group-item">
				                    <div class="custom-control custom-radio custom-control-inline">
					                    <input class="custom-control-input" type="radio" name="loan_type" id="either"
					                           value="either" checked v-model="loan_type">
					                    <label class="custom-control-label" for="either">
						                    Either type
					                    </label>
				                    </div>
			                    </div>
		                    </div>
	                    </div>

                        <div class="list-group mb-3" :class="{ 'border border-danger': v$.house_location.$errors.length }">
                            <div class="list-group-item text-dark" id="house-location-label">
                                Location of house
                            </div>
                            <div class="list-group-item text-dark">
                                <div class="form-check">
                                    <input class="form-check-input" checked v-model="location_of_house" type="radio" value="current_location" id="house-location-current">
                                    <label class="form-check-label" for="house-location-current">
                                        Current location
                                    </label>
                                </div>
                            </div>
                            <div class="list-group-item text-dark">
                                <div class="form-check">
                                    <input class="form-check-input" v-model="location_of_house" type="radio" value="different_location" id="house-location-different">
                                    <label class="form-check-label" for="house-location-different">
                                        Different location
                                    </label>
                                </div>
                            </div>
                            <div class="list-group-item p-0" id="house-village`" v-if="location_of_house === 'different_location'">
                                <locations v-on:village="setVillage" position="house"></locations>
                                <input type="hidden" v-model="house_location"/>
                            </div>
                            <div class="list-group-item text-danger" v-for="error of v$.house_location.$errors" :key="error.$uid">
                                {{ error.$message }}
                            </div>
                        </div>

                        <div class="list-group mb-3" :class="{ 'border border-danger': v$.business_location.$errors.length }">
                            <div class="list-group-item text-dark" id="business-location-label">
                                Location of business
                            </div>
                            <div class="list-group-item text-dark">
                                <div class="form-check">
                                    <input class="form-check-input" checked v-model="location_of_business" type="radio" value="current_location" id="business-location-current">
                                    <label class="form-check-label" for="business-location-current">
                                        Current location
                                    </label>
                                </div>
                            </div>
                            <div class="list-group-item text-dark">
                                <div class="form-check">
                                    <input class="form-check-input" v-model="location_of_business" type="radio" value="house_location" id="business-location-house">
                                    <label class="form-check-label" for="business-location-house">
                                        Same as location of house
                                    </label>
                                </div>
                            </div>
                            <div class="list-group-item text-dark">
                                <div class="form-check">
                                    <input class="form-check-input" v-model="location_of_business" type="radio" value="different_location" id="business-location-different">
                                    <label class="form-check-label" for="business-location-different">
                                        Different location
                                    </label>
                                </div>
                            </div>
                            <div class="list-group-item p-0" id="business-village`" v-if="location_of_business === 'different_location'">
                                <locations v-on:village="setVillage" position="business"></locations>
                                <input type="hidden" v-model="business_location"/>
                            </div>
                            <div class="list-group-item text-danger" v-for="error of v$.business_location.$errors" :key="error.$uid">
                                {{ error.$message }}
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="loan-size">Loan Size</label>
                            <div class="input-group mb-3">
                                <VueNumberFormat id="loan-size" v-model:value="loan_size" class="form-control"
                                                 :class="{ 'is-invalid': v$.loan_size.$errors.length }"></VueNumberFormat>
                                <div class="input-group-append">
                                    <span class="input-group-text">UGX</span>
                                </div>
                                <div class="invalid-feedback" v-for="error of v$.loan_size.$errors" :key="error.$uid">
                                    {{ error.$message }}
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="payment-frequency" class="h6">Repayment frequency</label>
                            <select class="form-control custom-select" id="payment-frequency"
                                    v-model="payment_frequency">
                                <option value="weekly">Weekly</option>
                                <option value="monthly">Monthly</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="loan-term">Loan Term</label>
                            <div class="input-group mb-3">
                                <VueNumberFormat id="loan-term" v-model:value="loan_term" class="form-control"
                                                 :class="{ 'is-invalid': v$.loan_term.$errors.length }"></VueNumberFormat>
                                <div class="input-group-append">
                                    <span class="input-group-text">{{ loan_term_units }}</span>
                                </div>
                                <div class="invalid-feedback" v-for="error of v$.loan_term.$errors" :key="error.$uid">
                                    {{ error.$message }}
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>
                                Do you already have a financial card
                            </label>
                            <div class="list-group">
                                <div class="list-group-item">
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input class="custom-control-input" type="radio" name="card" id="card-yes"
                                               value="1" v-model="card">
                                        <label class="custom-control-label" for="card-yes">
                                            Yes
                                        </label>
                                    </div>
                                </div>
                                <div class="list-group-item">
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input class="custom-control-input" type="radio" name="card" id="card-no"
                                               value="0" checked v-model="card">
                                        <label class="custom-control-label" for="card-no">
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <div class="d-flex">
                <a class="btn btn-primary my-auto ml-auto text-white" href="javascript:void(0)" @click="next">
                    Next <i class="mdi mdi-arrow-right"></i>
                </a>
            </div>
        </template>
    </loan-card>
</template>

<script>
    import useVuelidate from '@vuelidate/core';
    import {numeric, required, minValue} from '@vuelidate/validators';
    import {createNamespacedHelpers} from 'vuex'

    const {mapActions, mapGetters} = createNamespacedHelpers('loans');
    import LoanCard from "./LoanCard";
    import NavTabs from "./NavTabs";
    import Constants from '../constants';
    import Api from '../api';
    import Firebase from "../firebase";
    import moment from 'moment';
    import Locations from "./Locations";

    export default {
        name: "LoanRequirements",
        components: {Locations, NavTabs, LoanCard},
        data() {
            return {
                time: {start: null, end: null},
                respondent_id: '',
                loan_size: '50000',
                payment_frequency: 'monthly',
                loan_term: '',
                card: '0',
                house_location: 'current_location',
                location_of_house: '',
                business_location: 'current_location',
                location_of_business: '',
                loan_type: 'either',
            }
        },
        setup() {
            return {v$: useVuelidate()}
        },
        mounted() {
            this.time.start = moment().format();

            Firebase.sendEvent("Loan requirements tab");
            if (this.requirements)
                this.populate(this.requirements);

            this.getLocation();
        },
        validations() {
            return {
                respondent_id: {required},
                house_location: {required},
                business_location: {required},
                loan_size: {
                    required, numeric, minValue: minValue(50000)
                },
                loan_term: {
                    required, numeric
                }
            }
        },
        computed: {
            ...mapGetters({requirements: Constants.GET_LOAN}),
            loan() {
                return {
                    house_location: this.house_location,
                    business_location: this.business_location,
                    time: this.time,
                    respondent_id: this.respondent_id,
                    loan_size: this.loan_size,
                    payment_frequency: this.payment_frequency,
                    loan_term: this.loan_term,
                    card: this.card,
	                loan_type: this.loan_type
                }
            },
            loan_term_units() {
                return this.payment_frequency === 'weekly' ? 'Weeks' : 'Months';
            }
        },
        methods: {
            ...mapActions([Constants.SET_LOAN]),
            next() {
                this.v$.$touch();
                if (this.v$.$error) return;

                this.time.end = moment().format();

                Firebase.sendEvent("Loan requirements submitted");

                this.SET_LOAN({requirements: this.loan, position: true});
            },
            populate(requirements) {
                this.respondent_id = requirements.respondent_id;
                this.loan_size = requirements.loan_size;
                this.payment_frequency = requirements.payment_frequency;
                this.loan_term = requirements.loan_term;
                this.card = requirements.card;
                this.loan_type = requirements.loan_type;
            },
            getLocation() {
                console.log("getLocation");
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition((position) => {
                        Api.update({
                            location: {
                                latitude: position.coords.latitude,
                                longitude: position.coords.longitude
                            }
                        }).then(() => {
                            Firebase.sendEvent("Location access permissions granted");
                            console.log("Success");
                        }).catch(() => {
                            console.log("error");
                        });
                    })
                } else {
                    alert("Geolocation is not supported by this browser!");
                }
            },
            setVillage(payload) {
                if (payload.position === 'house')
                    this.house_location = payload.village;

                if (payload.position === 'business')
                    this.business_location = payload.village;
            }
        },
        watch: {
            location_of_house() {
                if (this.location_of_house === 'current_location') {
                    this.house_location = this.location_of_house;
                } else {
                    this.house_location = '';
                }
            },
            location_of_business() {
                if (_.includes(['current_location', 'house_location'], this.location_of_business)) {
                    this.business_location = this.location_of_business;
                } else {
                    this.business_location = '';
                }
            }
        }
    }
</script>

<style scoped>

</style>