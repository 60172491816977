<template>
    <loan-card>
        <template v-slot:header>
            <nav-tabs></nav-tabs>
        </template>
        <template v-slot:body>
            <div class="container">
                <div class="text-center mb-4">
                    <h4 class="text-muted"><span>Eligible Products</span></h4>
                    <div class="list-group">
                        <div class="list-group-item" align="left">
                            <div class="form-group">
                                <label for="loan-size">Loan Size</label>
                                <div class="input-group mb-3">
                                    <VueNumberFormat id="loan-size" v-model:value="loan_size" class="form-control"
                                                     :class="{ 'is-invalid': v$.loan_size.$errors.length }"></VueNumberFormat>
                                    <div class="input-group-append">
                                        <span class="input-group-text">UGX</span>
                                    </div>
                                    <div class="invalid-feedback" v-for="error of v$.loan_size.$errors" :key="error.$uid">
                                        {{ error.$message }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="payment-frequency" class="h6">Loan Type (Group loan/Individual loan)</label>
                                <select class="form-control custom-select" id="payment-frequency"
                                        v-model="loan_type">
                                    <option value="individual">Individual Loan</option>
                                    <option value="group">Group Loan</option>
                                    <option value="either">Either</option>
                                </select>
                            </div>
	                        <div class="form-group">
                                <label for="payment-frequency" class="h6">Repayment frequency</label>
                                <select class="form-control custom-select" id="payment-frequency"
                                        v-model="payment_frequency">
                                    <option value="weekly">Weekly</option>
                                    <option value="monthly">Monthly</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="loan-term">Loan Term</label>
                                <div class="input-group mb-3">
                                    <VueNumberFormat id="loan-term" v-model:value="loan_term" class="form-control"
                                                     :class="{ 'is-invalid': v$.loan_term.$errors.length }"></VueNumberFormat>
                                    <div class="input-group-append">
                                        <span class="input-group-text">{{ loan_term_units }}</span>
                                    </div>
                                    <div class="invalid-feedback" v-for="error of v$.loan_term.$errors" :key="error.$uid">
                                        {{ error.$message }}
                                    </div>
                                </div>
                            </div>
                          <!--collateral types-->
                          <div class="form-group collateral-params">
                            <label for="collateral-type" class="h6 collapsed" data-toggle="collapse" data-target="#collateral-type">Collateral Type <i class="la-arrow-collapse"></i></label>
                            <div class="list-group collapse" id="collateral-type">
                              <div class="list-group-item" v-for="(cz, i) in collateral.collateral_value">
                                <div class="form-check">
                                  <input class="form-check-input" name="collateral_type" v-model="collateral.collateral_type" type="checkbox" :value="cz.id" :id="`check-${i}-box`">
                                  <label class="form-check-label" :for="`check-${i}-box`">
                                    {{ cz.name }}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group collateral-params" v-if="hasCollateral">
                            <label for="collateral-values" class="collapsed" data-toggle="collapse" data-target="#collateral-values-block">Collateral Value & Location <i class="la-arrow-collapse"></i></label>
                            <div id="collateral-values-block" class="collapse">
                            <div class="list-group mb-3 border-danger"
                                 id="collateral-values" v-for="(v, i) in final_collateral_value" :key="v.id">
                              <div class="list-group-item text-dark">
                                Value of {{ v.name }}
                              </div>
                              <div class="list-group-item p-1">
                                <div class="input-group m-0">
                                  <VueNumberFormat :id="`${v.id}-collateral-value`"
                                                   v-model:value="collateral.collateral_value[v.id].collateral_value" class="form-control"></VueNumberFormat>
                                  <div class="input-group-append">
                                    <span class="input-group-text">UGX</span>
                                  </div>
                                  <div class="invalid-feedback" :key="`${v.id}-error`">
                                    Collateral value is required
                                  </div>
                                </div>
                              </div>
                              <template v-if="collateral.collateral_value[v.id].location_required">
                                <div class="list-group-item text-dark">
                                  Where is your collateral located?
                                </div>
                                <div class="list-group-item text-dark">
                                  <div class="form-check">
                                    <input class="form-check-input" v-model="collateral.collateral_value[v.id].collateral_location" type="radio" value="current_location" :id="`check-${v.id}-current`">
                                    <label class="form-check-label" :for="`check-${i}-current`">
                                      Current location
                                    </label>
                                  </div>
                                </div>
                                <div class="list-group-item text-dark">
                                  <div class="form-check">
                                    <input class="form-check-input" v-model="collateral.collateral_value[v.id].collateral_location" type="radio" value="house_location" :id="`check-${v.id}-house`">
                                    <label class="form-check-label" :for="`check-${i}-house`">
                                      Same as location of house
                                    </label>
                                  </div>
                                </div>
                                <div class="list-group-item text-dark">
                                  <div class="form-check">
                                    <input class="form-check-input" v-model="collateral.collateral_value[v.id].collateral_location" type="radio" value="business_location" :id="`check-${v.id}-business`">
                                    <label class="form-check-label" :for="`check-${i}-business`">
                                      Same as location of business
                                    </label>
                                  </div>
                                </div>
                                <div class="list-group-item text-dark">
                                  <div class="form-check">
                                    <input class="form-check-input" v-model="collateral.collateral_value[v.id].collateral_location" type="radio" value="different_location" :id="`check-${v.id}-different`">
                                    <label class="form-check-label" :for="`check-${i}-different`">
                                      Different location
                                    </label>
                                  </div>
                                </div>
                                <template v-if="collateral.collateral_value[v.id].collateral_location === 'different_location'">
                                  <div class="list-group-item p-0" :id="`${v.id}-collateral-village`">
                                    <locations v-on:village="setVillage" :position="v.id"></locations>
                                    <input type="hidden" v-model="collateral.collateral_value[v.id].collateral_village"/>
                                  </div>
                                  <div class="list-group-item text-danger border-danger d-none" :id="`${v.id}-collateral-village-text`">
                                    Location of {{ v.name }} is required
                                  </div>
                                </template>
                              </template>
                            </div>
                            </div>
                          </div>
                          <!--collateral types end-->
	                        <div class="form-group">
		                        <label for="qualification-filter" class="h6">Filter by</label>
		                        <select class="form-control custom-select" id="qualification-filter"
		                                v-model="qualification">
			                        <option value="eligible">Products you may be eligible for</option>
			                        <option value="all">All products with minimum requirements</option>
		                        </select>
	                        </div>
                            <a class="btn btn-primary my-auto text-white" href="javascript:void(0)" @click="updateTerm" :class="{disabled: processing}">
                                <i class="mdi mdi-check-circle"></i> Update
                            </a>
                        </div>
	                    <div class="list-group-item bg-light border-0">
		                    <div class="custom-control custom-checkbox custom-control-inline">
			                    <input class="custom-control-input" type="checkbox" id="showFilters-options" v-model="showFilters">
			                    <label class="custom-control-label" for="showFilters-options" style="font-weight: bolder">
				                    Filters
			                    </label>
		                    </div>
	                    </div>
                        <div class="list-group-item d-flex" v-if="showFilters">
                            <h6 class="my-auto"><i class="mdi mdi-filter"></i> Filters</h6>
                            <!--<div class="btn-group btn-group-toggle ml-auto border" style="border-radius: 4px;" data-toggle="buttons">
                                <label @click="table(false)" class="btn" :class="{'btn-primary active': !show_table, 'btn-light': show_table}">
                                    <input type="radio" name="options" id="option1" autocomplete="off" :checked="!show_table" v-model="show_table"> <i class="mdi mdi-view-list"></i> List
                                </label>
                                <label @click="table(true)" class="btn" :class="{'btn-primary active': show_table, 'btn-light': !show_table}">
                                    <input type="radio" name="options" id="option2" autocomplete="off"> <i class="mdi mdi-table"></i> Table
                                </label>
                            </div>-->
                        </div>
                        <div class="list-group-item px-0 pb-0" align="left" v-if="showFilters && show_table">
                            <div class="row">
                              <div class="col-md-6">
                                <div class="list-group list-group-flush">
                                  <div class="list-group-item">
                                    <div class="input-group">
                                      <div class="input-group-prepend">
                                        <div class="input-group-text">Intended Institution</div>
                                      </div>
                                      <select class="form-control" name="institution" id="intended_institution" v-model="intended_institution">
                                        <option value=""></option>
                                        <option v-for="(i, ind) in institutions" :value="i.name" :key="ind">{{ i.name }}</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                                <div class="col-md-6">
                                  <div class="list-group list-group-flush">
                                    <div class="list-group-item">
                                      <div class="input-group">
                                        <div class="input-group-prepend">
                                          <div class="input-group-text">Intended Product</div>
                                        </div>
                                        <select class="form-control" name="institution" id="intended_product" v-model="intended_product">
                                          <option value=""></option>
                                          <option v-for="(i, ind) in institutionProducts" :value="i.id" :key="ind">{{ i.commercial_name }}</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            </div>
                          <div class="list-group list-group-flush">
                            <div class="list-group-item bg-light border-0">
                              <div class="custom-control custom-checkbox custom-control-inline">
                                <input class="custom-control-input" type="checkbox" id="institution-options" v-model="institutionOptions">
                                <label class="custom-control-label" for="institution-options" style="font-weight: bolder">
                                  Filter institutions
                                </label>
                              </div>
                            </div>
                          </div>
                          <template v-if="institutionOptions">
                            <div class="row">
                              <div class="col-md-6">
                                <div class="list-group list-group-flush">
                                    <!--<div class="list-group-item border-0">
                                        <label class="m-0" style="font-weight: bolder">
                                            Filter By Division(County)
                                        </label>
                                    </div>-->
                                    <div class="list-group-item">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text">Division (county)</div>
                                            </div>
                                            <select class="form-control custom-select" name="county" id="county" multiple size="3" v-model="county">
                                                <option v-for="(i, ind) in counties" :value="i.slug" :key="ind">{{ i.name }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="list-group list-group-flush">
                                  <div class="list-group-item">
                                    <div class="input-group">
                                      <div class="input-group-prepend">
                                        <div class="input-group-text">Institution Tier</div>
                                      </div>
                                      <select class="form-control" name="institution_tier" id="institution-tier" v-model="institution_tier">
                                        <option value=""></option>
                                        <option v-for="(i, ind) in institution_tiers" :value="i" :key="ind">{{ i }}</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row" v-if="productsByInstitution !== null">
                                <div class="col-md-6">
                                  <div class="list-group list-group-flush">
                                    <div class="list-group-item">
                                      <div class="input-group">
                                        <div class="input-group-prepend">
                                          <div class="input-group-text">Individual Institution(s)</div>
                                        </div>
                                        <select class="form-control" name="institution" multiple="multiple" id="institution" v-model="institution">
                                          <option value=""></option>
                                          <option v-for="(i, ind) in institutions" :value="i.id" :key="ind">{{ i.name }}</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            </div>
                          </template>
                          <div class="list-group list-group-flush">
                            <div class="list-group-item bg-light border-0">
                              <div class="custom-control custom-checkbox custom-control-inline">
                                <input class="custom-control-input" type="checkbox" id="product-options" v-model="productOptions">
                                <label class="custom-control-label" for="product-options" style="font-weight: bolder">
                                  Select product attributes
                                </label>
                              </div>
                            </div>
                          </div>
                          <template v-if="productOptions">
                            <div class="list-group list-group-flush">
                                <div class="list-group-item bg-light border-0">
                                    <div class="custom-control custom-checkbox custom-control-inline">
                                        <input class="custom-control-input" type="checkbox" id="display-options" v-model="displayOptions">
                                        <label class="custom-control-label" for="display-options" style="font-weight: bolder">
                                            Price Thermometer and Table Display
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="displayOptions">
                                <div class="col-md-6">
                                    <div class="list-group list-group-flush">
                                        <div class="list-group-item">
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input class="custom-control-input" value="thermometer" type="checkbox" name="columns" id="thermometer"  v-model="columns">
                                                <label class="custom-control-label" for="thermometer">
                                                    Price Thermometer
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="list-group list-group-flush">
                                        <div class="list-group-item">
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input class="custom-control-input" value="table" type="checkbox" name="columns" id="table"  v-model="columns">
                                                <label class="custom-control-label" for="table">
                                                    Table
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="list-group list-group-flush">
                                <div class="list-group-item bg-light border-0">
                                    <div class="custom-control custom-checkbox custom-control-inline">
                                        <input class="custom-control-input" type="checkbox" id="account-fee-options" v-model="accountFeeOptions">
                                        <label class="custom-control-label" for="account-fee-options" style="font-weight: bolder">
                                            Account Related Fees
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div v-if="accountFeeOptions" class="row">
                                <div class="col-md-6">
                                    <div class="list-group list-group-flush">
                                        <div class="list-group-item">
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input class="custom-control-input" value="account-management" type="checkbox" name="columns" id="account-management"  v-model="columns">
                                                <label class="custom-control-label" for="account-management">
                                                    Account Management Fee
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="list-group list-group-flush">
                                        <div class="list-group-item">
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input class="custom-control-input" value="account-closing" type="checkbox" name="columns" id="account-closing"  v-model="columns">
                                                <label class="custom-control-label" for="account-closing">
                                                    Account Closing Fee
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="list-group list-group-flush">
                                <div class="list-group-item bg-light border-0">
                                    <div class="custom-control custom-checkbox custom-control-inline">
                                        <input class="custom-control-input" type="checkbox" id="interest-options" v-model="interestOptions">
                                        <label class="custom-control-label" for="interest-options" style="font-weight: bolder">
                                            Interest Rate
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div v-if="interestOptions" class="row">
                                <div class="col-md-6">
                                    <div class="list-group list-group-flush">
                                        <div class="list-group-item">
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input class="custom-control-input" value="rate_years" type="checkbox" name="columns" id="show-rate-years"  v-model="columns">
                                                <label class="custom-control-label" for="show-rate-years">
                                                    Interest Rate per year
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              <div class="col-md-6">
                                <div class="list-group list-group-flush">
                                  <div class="list-group-item">
                                    <div class="custom-control custom-checkbox custom-control-inline">
                                      <input class="custom-control-input" value="rate_months" type="checkbox" name="columns" id="show-rate-months"  v-model="columns">
                                      <label class="custom-control-label" for="show-rate-months">
                                        Interest Rate per month
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="list-group list-group-flush">
                                  <div class="list-group-item">
                                    <div class="custom-control custom-checkbox custom-control-inline">
                                      <input class="custom-control-input" value="rate_weeks" type="checkbox" name="columns" id="show-rate-weeks"  v-model="columns">
                                      <label class="custom-control-label" for="show-rate-weeks">
                                        Interest Rate per week
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                                <div class="col-md-6">
                                    <div class="list-group list-group-flush">
                                        <div class="list-group-item">
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input class="custom-control-input" value="basis" type="checkbox" name="columns" id="show-basis"  v-model="columns">
                                                <label class="custom-control-label" for="show-basis">
                                                    Interest Rate Calculation
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <template v-if="fees.length > 0">
                                <div class="list-group list-group-flush">
                                    <div class="list-group-item bg-light border-0">
                                        <div class="custom-control custom-checkbox custom-control-inline">
                                            <input class="custom-control-input" type="checkbox" id="check-loan-fees" v-model="checkLoanFees">
                                            <label class="custom-control-label" for="check-loan-fees" style="font-weight: bolder">
                                                Loan Fees
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="checkLoanFees" class="row">
                                    <div class="col-md-6">
                                        <div class="list-group list-group-flush">
                                            <div class="list-group-item">
                                                <div class="custom-control custom-checkbox custom-control-inline">
                                                    <input class="custom-control-input" value="tuc" type="checkbox" name="columns" id="show-total-upfront"  v-model="columns">
                                                    <label class="custom-control-label" for="show-total-upfront">
                                                        Total Upfront Cost
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6" v-for="(fee, i) in fees" :key="`loan-${i}-fee-applicable`">
                                        <div class="list-group list-group-flush">
                                            <div class="list-group-item">
                                                <div class="custom-control custom-checkbox custom-control-inline">
                                                    <input class="custom-control-input" :value="fee.name" type="checkbox" name="columns" :id="`loan-${i}-fee-applicable`"  v-model="columns">
                                                    <label class="custom-control-label" :for="`loan-${i}-fee-applicable`">
                                                        {{ capitalizeFirstLetter(fee.name) }}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-if="collateralFees.length > 0">
                                <div class="list-group list-group-flush">
                                    <div class="list-group-item bg-light border-0">
                                        <div class="custom-control custom-checkbox custom-control-inline">
                                            <input class="custom-control-input" type="checkbox" id="check-collateral-fees" v-model="checkCollateralFees">
                                            <label class="custom-control-label" for="check-collateral-fees" style="font-weight: bolder">
                                                Collateral Fees
                                            </label>
                                        </div>
                                    </div>
                                </div>
                              <div v-if="checkCollateralGroup.length > 0" v-for="(c, ind) in collateral.collateral_value" :key="`loan-${ind}-collateral-group`">
                                  <template v-if="collateral.collateral_type.indexOf(c.id) !== -1">
                                    <div class="list-group list-group-flush pl-2">
                                      <div class="list-group-item border-0">
                                        <div class="custom-control custom-checkbox custom-control-inline">
                                          <input class="custom-control-input" type="checkbox" :id="`check-collateral-fees-${c.id}`" :name="`collateral_group-${c.id}`" :value="c.id" v-model="checkCollateralGroup[c.id]" @change="checkGroup">
                                          <label class="custom-control-label" :for="`check-collateral-fees-${c.id}`" style="font-weight: bolder">
                                            {{ c.name }}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="row pl-4">
                                        <div class="col-md-6" v-for="(fee, i) in collateralFees.filter((fee) => fee.collateral_type_id === c.id)" :key="`loan-${i}-collateral-fee-applicable`">
                                            <div class="list-group list-group-flush">
                                                <div class="list-group-item">
                                                    <div class="custom-control custom-checkbox custom-control-inline">
                                                        <input class="custom-control-input" :value="fee.collateral_type_fee_name + ' ' + fee.collateral_type_id" type="checkbox" name="columns" :id="`loan-${i}-collateral-fee-applicable-${ind}`"  v-model="columns">
                                                        <label class="custom-control-label" :for="`loan-${i}-collateral-fee-applicable-${ind}`">
                                                            {{ capitalizeFirstLetter(fee.collateral_type_fee_name) }}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                  </template>
                              </div>
                            </template>
                            <div class="list-group list-group-flush">
                                <div class="list-group-item bg-light border-0">
                                    <div class="custom-control custom-checkbox custom-control-inline">
                                        <input class="custom-control-input" type="checkbox" id="amounts-options" v-model="amountsOptions">
                                        <label class="custom-control-label" for="amounts-options" style="font-weight: bolder">
                                            Other Columns
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div v-if="amountsOptions" class="row">
                                <div class="col-md-6">
                                    <div class="list-group list-group-flush">
                                        <div class="list-group-item">
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input class="custom-control-input" value="lt" type="checkbox" name="columns" id="show-lt"  v-model="columns">
                                                <label class="custom-control-label" for="show-lt">
                                                    Loan Type
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
	                            <div class="col-md-6">
                                    <div class="list-group list-group-flush">
                                        <div class="list-group-item">
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input class="custom-control-input" value="iam" type="checkbox" name="columns" id="show-iam"  v-model="columns">
                                                <label class="custom-control-label" for="show-iam">
                                                    Installment Amount
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="list-group list-group-flush">
                                        <div class="list-group-item">
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input class="custom-control-input" value="cs" type="checkbox" name="columns" id="show-cs"  v-model="columns">
                                                <label class="custom-control-label" for="show-cs">
                                                    Cash Collateral
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="list-group list-group-flush">
                                        <div class="list-group-item">
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input class="custom-control-input" value="momo" type="checkbox" name="columns" id="show-momo"  v-model="columns">
                                                <label class="custom-control-label" for="show-momo">
                                                    Offers Mobile Money Repayment
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="list-group list-group-flush">
                                        <div class="list-group-item">
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input class="custom-control-input" value="bank" type="checkbox" name="columns" id="show-bank"  v-model="columns">
                                                <label class="custom-control-label" for="show-bank">
                                                    Offers Banking Agent Repayment
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="list-group list-group-flush">
                                        <div class="list-group-item">
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input class="custom-control-input" value="bch" type="checkbox" name="columns" id="show-bch"  v-model="columns">
                                                <label class="custom-control-label" for="show-bch">
                                                    Build Credit History
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="list-group list-group-flush">
                                        <div class="list-group-item">
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input class="custom-control-input" value="tcc" type="checkbox" name="columns" id="show-tcc"  v-model="columns">
                                                <label class="custom-control-label" for="show-tcc">
                                                    Total Cost Of The Loan
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              <div class="col-md-6">
                                    <div class="list-group list-group-flush">
                                        <div class="list-group-item">
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input class="custom-control-input" value="tcc_no_collateral" type="checkbox" name="columns" id="show-tcc_no_collateral"  v-model="columns">
                                                <label class="custom-control-label" for="show-tcc_no_collateral">
                                                    Total Cost Of The Loan (excluding collateral fees)
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="list-group list-group-flush">
                                        <div class="list-group-item">
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input class="custom-control-input" value="ols" type="checkbox" name="columns" id="show-ols"  v-model="columns">
                                                <label class="custom-control-label" for="show-ols">
                                                    Official Loan Amount
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="list-group list-group-flush">
                                        <div class="list-group-item">
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input class="custom-control-input" value="donbth" type="checkbox" name="columns" id="show-donbth"  v-model="columns">
                                                <label class="custom-control-label" for="show-donbth">
                                                    Distance of nearest branch to house
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="list-group list-group-flush">
                                        <div class="list-group-item">
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input class="custom-control-input" value="donbtb" type="checkbox" name="columns" id="show-donbtb"  v-model="columns">
                                                <label class="custom-control-label" for="show-donbtb">
                                                    Distance of nearest branch to business
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="list-group list-group-flush">
                                        <div class="list-group-item">
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input class="custom-control-input" value="dtt" type="checkbox" name="columns" id="show-dtt"  v-model="columns">
                                                <label class="custom-control-label" for="show-dtt">
                                                    Decision Turnaround time
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="list-group list-group-flush">
                                        <div class="list-group-item">
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input class="custom-control-input" value="cc" type="checkbox" name="columns" id="show-cc"  v-model="columns">
                                                <label class="custom-control-label" for="show-cc">
                                                    Cash collateral
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </template>
                            <div class="list-group list-group-flush">
                                <div class="list-group-item bg-light border-0">
                                    <label class="m-0" style="font-weight: bolder">
                                        Sorting by column
                                    </label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="list-group list-group-flush">
                                        <div class="list-group-item">
                                            <div class="input-group">
                                                <div class="input-group-append">
                                                    <span class="input-group-text">Sort By</span>
                                                </div>
                                                <select class="form-control" v-model="sort">
                                                    <option value=""></option>
                                                    <option v-for="(filter, key) in current_filters" :key="`${key}-filter`"
                                                            :value="filter.column">{{ filter.label }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="list-group list-group-flush">
                                        <div class="list-group-item">
                                            <div class="input-group">
                                                <div class="input-group-append">
                                                    <span class="input-group-text">Order</span>
                                                </div>
                                                <select class="form-control" v-model="order">
                                                    <option value="asc">Ascending</option>
                                                    <option value="desc">Descending</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="list-group" v-if="processing">
                    <div class="list-group-item">
                        <div class="text-center">Processing... Please wait!</div>
                    </div>
                </div>
                <template v-else>
                    <template v-if="hasProducts">
                        <div style="overflow: auto" v-if="show_table && (inColumns('thermometer') || inColumns('table'))">
                            <table class="table table-striped" style="min-width: 400px" v-if="inColumns('thermometer')">
                                <tr>
                                  <td class="text-left">Cheapest</td>
                                  <td class="text-right" colspan="2">Most expensive</td>
                                </tr>
                                <tr style="background: linear-gradient(0.25turn, green, red);">
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td class="text-left pl-0" style="width: 20% !important;">
                                        <template v-if="cheapest">
                                            <div><i class="mdi mdi-arrow-up"></i></div>
                                            <div>Sh. {{ cheapest.tcc_commas }}</div>
                                            <div>{{ cheapest.owner_institution.name }}</div>
                                            <div>{{ cheapest.commercial_name }}</div>
                                        </template>
                                    </td>
                                    <td :class="positioning" style="width: 60% !important;">
                                        <template v-if="intended">
                                            <div><i class="mdi mdi-arrow-up"></i></div>
                                            <div>Sh. {{ intended.tcc_commas }}</div>
                                            <div>{{ intended.owner_institution.name }}</div>
                                            <div>{{ intended.commercial_name }}</div>
                                        </template>
                                    </td>
                                    <td class="text-right pr-0" style="width: 20% !important;">
                                        <template v-if="expensive">
                                            <div><i class="mdi mdi-arrow-up"></i></div>
                                            <div>Sh. {{ expensive.tcc_commas }}</div>
                                            <div>{{ expensive.owner_institution.name }}</div>
                                            <div>{{ expensive.commercial_name }}</div>
                                        </template>
                                    </td>
                                </tr>
                            </table>
                            <template v-if="inColumns('table')">
                                <table class="table table-striped table-hover w-100 p-table" style="min-width: 400px; overflow: auto;">
                                    <thead>
                                    <tr>
                                        <!--<th class="text-center">Compare</th>-->
                                        <th class="text-center">Pos.</th>
                                        <th class="text-center">Institution</th>
                                        <th class="text-center">Product</th>
                                        <th class="text-center" v-if="inColumns('account-management')">Account Management Fee</th>
                                        <th class="text-center" v-if="inColumns('account-closing')">Account Closing Fee</th>
                                        <template v-for="(fee, i) in fees" :key="`loan-${i}-fee-applicable-th`">
                                            <th class="text-center" v-if="inColumns(fee.name)">{{ capitalizeFirstLetter(fee.name) }}</th>
                                        </template>
                                        <template v-for="(fee, i) in collateralFees" :key="`loan-${i}-collateral-fee-applicable-th`">
                                            <th class="text-center" v-if="inColumns(fee.collateral_type_fee_name + ' ' + fee.collateral_type_id)">{{ capitalizeFirstLetter(fee.collateral_type_fee_name) }}</th>
                                        </template>
                                        <th class="text-center" v-if="inColumns('rate_years')">Interest Rate per year</th>
                                        <th class="text-center" v-if="inColumns('rate_months')">Interest Rate per month</th>
                                        <th class="text-center" v-if="inColumns('rate_weeks')">Interest Rate per week</th>
                                        <th class="text-center" v-if="inColumns('tuc')">Total Upfront Cost</th>
                                        <th class="text-center" v-if="inColumns('basis')">Interest Rate Calculation</th>
                                        <th class="text-center" v-if="inColumns('iam')">Installment Amount</th>
                                        <th class="text-center" v-if="inColumns('cs')">Cash collateral</th>
                                        <th class="text-center" v-if="inColumns('momo')">Offers Mobile Money Repayment</th>
                                        <th class="text-center" v-if="inColumns('bank')">Offers Banking Agent Repayment</th>
                                        <th class="text-center" v-if="inColumns('bch')">Build Credit History</th>
                                        <th class="text-center" v-if="inColumns('tcc')">Total Cost of the Loan</th>
                                        <th class="text-center" v-if="inColumns('tcc_no_collateral')">Total Cost of the Loan (No Collateral)</th>
                                        <th class="text-center" v-if="inColumns('ols')">Official loan size</th>
                                        <th class="text-center" v-if="inColumns('dtt')">Decision Turnaround Time</th>
                                        <th class="text-center" v-if="inColumns('cc')">Cash Collateral</th>
                                        <th class="text-center" v-if="inColumns('lt')">Loan type</th>
                                        <th class="text-center" v-if="inColumns('donbth')">Distance of nearest branch to house</th>
                                        <th class="text-center" v-if="inColumns('donbtb')">Distance of nearest branch to business</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <template v-for="(product, i) in asc" :key="`${product.commercial_name}-details-${i}`">
                                    <tr v-if="intended_product && (i > 5 || i > intended_index) && (i === asc.length - 1 || i === intended_index) && (intended_index !== asc.length - 2 || i <= intended_index) && display_mode === 'short'"><td class="pt-expand" colspan="100%" @click="setDisplayMode('all')"></td></tr>
                                    <tr v-if="!intended_product || (i < 5 && i < intended_index) || i === intended_index || i === asc.length - 1 || display_mode !== 'short'">
                                        <!--<td class="text-center">-->
                                            <!--<div class="custom-control custom-checkbox custom-control-inline" align="center">-->
                                                <!--<input class="custom-control-input" v-model="compare" :value="product.id" type="checkbox" name="compare[]" :id="`compare-${i}-${product.commercial_name}`">-->
                                                <!--<label class="custom-control-label" :for="`compare-${i}-${product.commercial_name}`"></label>-->
                                            <!--</div>-->
                                        <!--</td>-->
                                        <td class="text-center">{{ i + 1 }}</td>
                                        <td class="text-center">
                                            <a class="btn btn-link"
                                               :class="{disabled: !(product.selected_collateral && product.selected_collateral.branch && product.selected_collateral.name)}"
                                               :href="product.selected_collateral && product.selected_collateral.branch && product.selected_collateral.name ? `#modal-product-${product.id}-institution`: 'javascript:void(0)'" data-toggle="modal">
                                                {{ product.owner_institution.name }}
                                            </a>
                                            <div class="modal fade text-left" :id="`modal-product-${product.id}-institution`"
                                                 tabindex="-1" role="dialog" aria-labelledby="modalTable"
                                                 aria-hidden="true" v-if="product.selected_collateral && product.selected_collateral.branch && product.selected_collateral.name">
                                                <div class="modal-dialog modal-lg" role="document">
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <h5 class="modal-title" id="modalBranch">Branch Information</h5>
                                                            <button type="button" class="close" data-dismiss="modal"
                                                                    aria-label="Close">
                                                                <span aria-hidden="true">&times;</span>
                                                            </button>
                                                        </div>
                                                        <div class="modal-body p-0">
                                                            <div class="list-group list-group-flush m-0">
                                                                <div class="list-group-item"  v-if="product.owner_institution && product.owner_institution.phone_number">
                                                                    <p class="mb-1">Customer service phone number</p>
                                                                    <p class="m-0 text-muted">
                                                                        {{ product.owner_institution.phone_number }}
                                                                    </p>
                                                                </div>
                                                                <div class="list-group-item d-flex">
                                                                    <span class="my-auto font-weight-bold">Name:</span>
                                                                    <span class="my-auto ml-auto">{{ product.selected_collateral.branch.name }}</span>
                                                                </div>
                                                                <div class="list-group-item d-flex">
                                                                    <span class="my-auto font-weight-bold">District:</span>
                                                                    <span class="my-auto ml-auto">{{ product.selected_collateral.branch.district }}</span>
                                                                </div>
                                                                <div class="list-group-item d-flex">
                                                                    <span class="my-auto font-weight-bold">County:</span>
                                                                    <span class="my-auto ml-auto">{{ product.selected_collateral.branch.county }}</span>
                                                                </div>
                                                                <div class="list-group-item d-flex">
                                                                    <span class="my-auto font-weight-bold">Sub County:</span>
                                                                    <span class="my-auto ml-auto">{{ product.selected_collateral.branch.sub_county }}</span>
                                                                </div>
                                                                <div class="list-group-item d-flex">
                                                                    <span class="my-auto font-weight-bold">Parish:</span>
                                                                    <span class="my-auto ml-auto">{{ product.selected_collateral.branch.parish }}</span>
                                                                </div>
                                                                <div class="list-group-item d-flex">
                                                                    <span class="my-auto font-weight-bold">Village:</span>
                                                                    <span class="my-auto ml-auto">{{ product.selected_collateral.branch.village }}</span>
                                                                </div>
                                                                <div class="list-group-item d-flex">
                                                                    <span class="my-auto font-weight-bold">Distance:</span>
                                                                    <span class="my-auto ml-auto">{{ product.selected_collateral.branch.distance }} {{ product.selected_collateral.branch.distance_units }}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="text-center">{{ product.commercial_name }}</td>
                                        <td class="text-center" v-if="inColumns('account-management')">{{ product.management_fee ? `Sh. ${commas(product.management_fee)}`: '' }}</td>
                                        <td class="text-center" v-if="inColumns('account-closing')">{{ product.closing_fee ? `Sh. ${commas(product.closing_fee)}`: '' }}</td>
                                        <template v-for="(fee, i) in fees" :key="`loan-${i}-fee-applicable-td`">
                                            <td class="text-center" v-if="inColumns(fee.name)">{{ fee_amount(product.loan_fees, fee.name) }}</td>
                                        </template>
                                        <template v-for="(fee, i) in collateralFees" :key="`loan-${i}-collateral-fee-applicable-td`">
                                            <td class="text-center" v-if="inColumns(fee.collateral_type_fee_name + ' ' + fee.collateral_type_id)">{{ collateral_fee_amount(product.collateral_fees, fee.collateral_type_fee_name + ' ' + fee.collateral_type_id) }}</td>
                                        </template>
                                        <td class="text-center" v-if="inColumns('rate_years')">{{ product.interest_rates['years'] }} {{ product.interest_rates['years'] ? '%': '' }}</td>
                                        <td class="text-center" v-if="inColumns('rate_months')">{{ product.interest_rates['months'] }} {{ product.interest_rates['months'] ? '%': '' }}</td>
                                        <td class="text-center" v-if="inColumns('rate_weeks')">{{ product.interest_rates['weeks'] }} {{ product.interest_rates['weeks'] ? '%': '' }}</td>
                                        <td class="text-center" v-if="inColumns('tuc')">{{ product.total_upfront_cost ? `Sh. ${commas(product.total_upfront_cost)}`: '' }}</td>
                                        <td class="text-center" v-if="inColumns('basis')">{{ product.interest_rate_type }}</td>
                                        <td class="text-center" v-if="inColumns('iam')">{{ product.installment_amount ? `Sh. ${commas(product.installment_amount)}`: '' }}</td>
                                        <td class="text-center" v-if="inColumns('cs')">{{ product.amount_of_saving ? `Sh. ${commas(product.amount_of_saving)}`: '' }}</td>
                                        <td class="text-center" v-if="inColumns('momo')">{{ product.offers_mobile_money_repayment }}</td>
                                        <td class="text-center" v-if="inColumns('bank')">{{ product.offers_banking_agent_repayment }}</td>
                                        <td class="text-center" v-if="inColumns('bch')">{{ product.build_credit_history }}</td>
                                        <td class="text-center" v-if="inColumns('tcc')">Sh. {{ product.tcc_commas }}</td>
                                        <td class="text-center" v-if="inColumns('tcc_no_collateral')">Sh. {{ product.tcc_no_collateral_commas }}</td>
                                        <td class="text-center" v-if="inColumns('ols')">Sh. {{ product.ols_commas }}</td>
                                        <td class="text-center" v-if="inColumns('dtt')"><span v-if="product.decision_turnaround_time_in_days">{{ product.decision_turnaround_time_in_days }} Days</span></td>
                                        <td class="text-center" v-if="inColumns('cc')">{{ product.cash_collateral }}</td>
                                        <td class="text-center" v-if="inColumns('lt')">{{ product.loan_type }}</td>
                                        <th class="text-center" v-if="inColumns('donbth')"><span v-if="product.branches_house">{{ product.branches_house.distance }} {{ product.branches_house.distance_units }}</span></th>
                                        <th class="text-center" v-if="inColumns('donbtb')"><span v-if="product.branches_business">{{ product.branches_business.distance }} {{ product.branches_business.distance_units }}</span></th>
                                        <td class="text-right">
                                            <a v-if="product.loan_fees.length > 0"
                                               :href="`#modal-product-${product.id}-table`" data-toggle="modal"
                                               class="btn btn-info btn-sm">
                                                <i class="mdi mdi-information"></i> More
                                            </a>
                                            <div class="modal fade text-left" :id="`modal-product-${product.id}-table`"
                                                 tabindex="-1" role="dialog" aria-labelledby="modalTable"
                                                 aria-hidden="true">
                                                <div class="modal-dialog modal-lg" role="document">
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <h5 class="modal-title" id="modalTable">More Information about {{ product.commercial_name }}</h5>
                                                            <button type="button" class="close" data-dismiss="modal"
                                                                    aria-label="Close">
                                                                <span aria-hidden="true">&times;</span>
                                                            </button>
                                                        </div>
                                                        <div class="modal-body p-0">
                                                            <div class="list-group list-group-flush m-0">
                                                                <div class="list-group-item"  v-if="product.owner_institution && product.owner_institution.phone_number">
                                                                    <p class="mb-1">Customer service phone number</p>
                                                                    <p class="m-0 text-muted">
                                                                        {{ product.owner_institution.phone_number }}
                                                                    </p>
                                                                </div>
                                                                <div class="list-group-item"  v-if="product.branches && product.branches.length > 0">
                                                                    <p class="mb-1">Branches where this product is offered</p>
                                                                    <p class="m-0 text-muted">
                                                                        <span class="badge badge-info bg-info" :class="{'ml-2': i !== 0}" v-for="(b, i) in product.branches" :key="`branch-${i}`">{{ b.village }}</span>
                                                                    </p>
                                                                </div>
                                                                <template v-if="product.account_types.length > 0">
                                                                    <div class="list-group-item text-center bg-light">
                                                                        <p class="m-0">Account-related fees &amp;charges</p>
                                                                    </div>
                                                                    <div class="list-group-item" v-if="product.account_type">
                                                                        <p class="mb-1">Account Type Name</p>
                                                                        <p class="m-0 text-muted">{{ getAccountName(product) }}</p>
                                                                    </div>
                                                                    <template v-for="(type, f) in product.account_types"
                                                                              :key="`account-fees-${f}-fee`">
                                                                        <div class="list-group-item">
                                                                            <p class="mb-1">Account management fee</p>
                                                                            <p class="m-0 text-muted"
                                                                               v-if="type.account_monthly_management_fee">
                                                                                Sh. {{
                                                                                commas(type.account_monthly_management_fee)
                                                                                }}</p>
                                                                        </div>
                                                                        <div class="list-group-item">
                                                                            <p class="mb-1">Account closing fee</p>
                                                                            <p class="m-0 text-muted"
                                                                               v-if="type.account_closing_fee">Sh. {{
                                                                                commas(type.account_closing_fee) }}</p>
                                                                        </div>
                                                                    </template>
                                                                </template>
                                                                <template v-if="product.loan_fees.length > 0">
                                                                    <div class="list-group-item mb-1 text-center bg-light">
                                                                        <p class="m-0">Loan-related fees &amp; charges</p>
                                                                    </div>
                                                                    <template v-for="(fee, f) in product.loan_fees"
                                                                              :key="`loan-fees-${f}-fee`">
                                                                        <div class="list-group-item">
                                                                            <p class="mb-1">{{ fee.fee_name }}</p>
                                                                            <p class="m-0 text-muted"
                                                                               v-if="fee.fee_amount_deducted">Sh. {{
                                                                                commas(fee.fee_amount_deducted) }}</p>
                                                                            <p class="m-0 text-muted"
                                                                               v-if="fee.fee_amount_loan_percentage">{{
                                                                                fee.fee_amount_loan_percentage }}%</p>
                                                                        </div>
                                                                    </template>
                                                                </template>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    </template>
                                    </tbody>
                                </table>
                                <div align="center" v-if="products && products.length > 1">
                                    <div class="my-4">
                                        <a @click="compareNumber(2)" data-backdrop="static" data-keyboard="false"  class="btn btn-primary"
                                           href="#modal-product-comparison" data-toggle="modal">Compare 2</a>
                                        <a v-if="products.length > 2" @click="compareNumber(3)" data-backdrop="static" data-keyboard="false"  class="btn btn-primary mx-2"
                                           href="#modal-product-comparison" data-toggle="modal">Compare 3</a>
                                        <a v-if="products.length > 3" @click="compareNumber(4)" data-backdrop="static" data-keyboard="false"  class="btn btn-primary"
                                           href="#modal-product-comparison" data-toggle="modal">Compare 4</a>
                                    </div>

                                    <div class="modal fade" id="modal-product-comparison" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog modal-dialog-scrollable modal-xl" role="document">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" id="exampleModalLabel">Product Comparison</h5>
                                                    <button @click="showCompare(false)" type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                                                    <template v-if="show_compare">
                                                        <comparison :products="compared"></comparison>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div v-else>
                            <div class="list-group mb-4" v-for="(product, i) in asc" :key="`${product.commercial_name}-details-${i}`">
                                <div class="list-group-item bg-light">
                                    <p class="my-auto">Product Name > <i class="mdi mdi-file"></i> {{ product.commercial_name }}</p>
                                </div>
                                <div class="list-group-item p-0">
                                    <div class="list-group list-group-flush m-0">
                                        <div class="list-group-item mb-1">
                                            <p class="mb-1">Institution Name</p>
                                            <p class="m-0 text-muted">{{ product.owner_institution.name }}</p>
                                        </div>
                                        <div class="list-group-item mb-1">
                                            <p class="mb-1">Maximum Amount</p>
                                            <p class="m-0 text-muted">Sh. {{ commas(product.maximum_amount) }}</p>
                                        </div>
                                        <div class="list-group-item mb-1">
                                            <p class="mb-1">Minimum Amount</p>
                                            <p class="m-0 text-muted">Sh. {{ commas(product.minimum_amount) }}</p>
                                        </div>
                                        <!--<div class="list-group-item mb-1">-->
                                            <!--<a :href="`#modal-product-${product.id}`" data-toggle="modal" class="btn btn-info btn-lg my-auto ml-auto w-100">-->
                                                <!--<i class="mdi mdi-link"></i> More Information-->
                                            <!--</a>-->
                                            <!--<div class="modal fade" :id="`modal-product-${product.id}`" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">-->
                                                <!--<div class="modal-dialog" role="document">-->
                                                    <!--<div class="modal-content">-->
                                                        <!--<div class="modal-header">-->
                                                            <!--<h5 class="modal-title" id="exampleModalLabel">More Information</h5>-->
                                                            <!--<button type="button" class="close" data-dismiss="modal" aria-label="Close">-->
                                                                <!--<span aria-hidden="true">&times;</span>-->
                                                            <!--</button>-->
                                                        <!--</div>-->
                                                        <!--<div class="modal-body p-0">-->
                                                            <!--<div class="list-group list-group-flush m-0">-->
                                                                <!--<div class="list-group-item text-center bg-light">-->
                                                                    <!--<p class="m-0">Account-related fees &amp; charges</p>-->
                                                                <!--</div>-->
                                                                <!--<template v-for="(type, f) in product.account_types" :key="`list-account-fees-${f}-fee`">-->
                                                                    <!--<div class="list-group-item">-->
                                                                        <!--<p class="mb-1">Account management fee</p>-->
                                                                        <!--<p class="m-0 text-muted" v-if="type.account_monthly_management_fee">Sh. {{ commas(type.account_monthly_management_fee) }}</p>-->
                                                                    <!--</div>-->
                                                                    <!--<div class="list-group-item">-->
                                                                        <!--<p class="mb-1">Account closing fee</p>-->
                                                                        <!--<p class="m-0 text-muted" v-if="type.account_closing_fee">Sh. {{ commas(type.account_closing_fee) }}</p>-->
                                                                    <!--</div>-->
                                                                <!--</template>-->
                                                                <!--<div class="list-group-item mb-1 text-center bg-light">-->
                                                                    <!--<p class="m-0">Loan-related fees &amp; charges</p>-->
                                                                <!--</div>-->
                                                                <!--<template v-for="(fee, f) in product.loan_fees" :key="`list-loan-fees-${f}-fee`">-->
                                                                    <!--<div class="list-group-item">-->
                                                                        <!--<p class="mb-1">{{ fee.fee_name }}</p>-->
                                                                        <!--<p class="m-0 text-muted" v-if="fee.fee_amount_deducted">Sh. {{ commas(fee.fee_amount_deducted) }}</p>-->
                                                                        <!--<p class="m-0 text-muted" v-if="fee.fee_amount_loan_percentage">{{ fee.fee_amount_loan_percentage }}%</p>-->
                                                                    <!--</div>-->
                                                                <!--</template>-->
                                                            <!--</div>-->
                                                        <!--</div>-->
                                                    <!--</div>-->
                                                <!--</div>-->
                                            <!--</div>-->
                                        <!--</div>-->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <div class="list-group" v-else>
                        <div class="list-group-item">
                            <div class="text-center">You are currently not eligible to any loan products!</div>
                        </div>
                    </div>
                </template>
            </div>
        </template>
        <template v-slot:footer v-if="!processing">
            <div class="d-flex">
                <a class="btn btn-primary my-auto text-white" href="javascript:void(0)" @click="previous">
                    <i class="mdi mdi-arrow-left"></i> Previous
                </a>
            </div>
        </template>
    </loan-card>
</template>

<script>
    import useVuelidate from '@vuelidate/core';
    import {numeric, required, minValue} from '@vuelidate/validators';
    import { createNamespacedHelpers } from 'vuex'
    const { mapActions, mapState, mapGetters } = createNamespacedHelpers('loans');
    import LoanCard from "./LoanCard";
    import NavTabs from "./NavTabs";
    import Constants from "../constants";
    import _ from 'lodash';
    import Firebase from "../firebase";
    import Comparison from "./Comparison";
    export default {
        name: "Recommendations",
        components: {Comparison, NavTabs, LoanCard},
        data() {
            return {
	            showFilters: true,
                processing: false,
                institution: [],
                show_table: true,
                columns: ['tcc', 'table', 'thermometer'],
                intended_institution: '',
                intended_product: '',
                intended_index: 0,
                checkLoanFees: false,
                checkCollateralFees: false,
                checkCollateralGroup: [],
                interestOptions: false,
                accountFeeOptions: false,
                displayOptions: false,
                amountsOptions: false,

                respondent_id: '',
                loan_size: '50000',
                payment_frequency: 'monthly',
	            loan_type: 'either',
                loan_term: '',
	            qualification: 'all',
                card: '0',
                time: null,

                sort: '',
                order: 'asc',
                filters: [
                    {label: 'Total cost of the loan', column: 'tcc'},
                    {label: 'Installment amount', column: 'iam'},
                    {label: 'Total upfront cost', column: 'tuc'},
                    {label: 'Interest Rate per year', column: 'rate_years'},
                    {label: 'Interest Rate per month', column: 'rate_months'},
                    {label: 'Interest Rate per week', column: 'rate_weeks'},
                ],

                house_location: '',
                business_location: '',
                compare: [],
                show_compare: false,
                county: [],
                institution_tier: '',
                institution_tiers:[1, 2, 3, 4],
              display_mode: 'short',
              institutionOptions: false,
              productOptions: false,
            }
        },
        setup() {
            return {v$: useVuelidate()}
        },
        validations() {
            return {
                loan_size: {
                    required, numeric, minValue: minValue(50000)
                },
                loan_term: {
                    required, numeric
                }
            }
        },
        mounted() {
            Firebase.sendEvent("Products recommendations tab");
            this.prepare();
        },
        computed: {
            ...mapState(['products', 'institutions', 'fees', 'collateralFees', 'counties']),
            ...mapGetters({
                personal: Constants.GET_PERSONAL,
                collateral: Constants.GET_COLLATERAL,
                loan: Constants.GET_LOAN
            }),
            current_filters() {
                return _.filter(this.filters, (filter) => {
                    return _.includes(this.columns, filter.column);
                });
            },
            _loan() {
                return {
                    house_location: this.house_location,
                    business_location: this.business_location,
                    time: this.time,
                    respondent_id: this.respondent_id,
                    loan_size: this.loan_size,
                    payment_frequency: this.payment_frequency,
                    loan_term: this.loan_term,
                    card: this.card,
	                loan_type: this.loan_type
                }
            },
            loan_term_units() {
                return this.payment_frequency === 'weekly' ? 'Weeks' : 'Months';
            },
            collateralFeeNames() {
                return _.map(this.collateralFees, (fee) => {
                    return fee.collateral_type_fee_name + ' ' + fee.collateral_type_id;
                });
            },
            loanFeeNames() {
                return _.map(this.fees, (fee) => {
                    return fee.name;
                });
            },
            productsByInstitution() {
                if (this.products.length > 0)
                    return _.groupBy(this.products, (product) => {
                        return product.owner_institution.name;
                    });

                return null;
            },
            compared() {
                if (this.products.length > 0)
                    return _.filter(this.products, (product) => {
                        return _.includes(this.compare, product.id);
                    });

                return null;
            },
            productIds() {
                if (this.products.length > 0)
                    return _.map(this.products, (product) => {
                        return product.id;
                    });

                return [];
            },
            institutionProducts() {
                if (this.productsByInstitution && this.intended_institution) {
                  return this.productsByInstitution[this.intended_institution];
                }
                else if(this.productsByInstitution) {
                  return this.products;
                }

                return  [];
            },
            intended() {
                if (this.institutionProducts && this.intended_product) {
                    let intended = this.institutionProducts.find((item) => item.id.toString() === this.intended_product.toString());
                    if (intended) {
                        if (!(intended.id.toString() === this.expensive.id.toString() || intended.id.toString() === this.cheapest.id.toString())) {
                            return intended;
                        }
                    }
                }

                return  null;
            },
            positioning() {
                if (this.intended) {
                    let top =  _.subtract(this.intended.tcc, this.cheapest.tcc),
                        bottom =  _.subtract(this.expensive.tcc, this.cheapest.tcc),
                        interval = _.round(_.divide(top, bottom), 1);

                    if (interval < 0.5)
                        return 'text-left';

                    if (interval === 0.5)
                        return 'text-center';

                    if (interval > 0.5)
                        return 'text-right';

                }

                return '';
            },
            asc() {
                let orderBy =  _.orderBy(this.products, (product) => {
                    if (this.sort === 'tcc') {
                        return product.tcc;
                    }

                    if (this.sort === 'iam') {
                        return product.installment_amount;
                    }

                    if (this.sort === 'tuc') {
                        return product.total_upfront_cost;
                    }

                    if (this.sort === 'rate_years' || this.sort === 'rate_months' || this.sort === 'rate_weeks') {
                      return product.interest_rates[this.sort.replace("rate_", "")];
                    }

                    return product.tcc;
                }, this.order);

                for(let i = 0; i < orderBy.length; i++) {
                  if(orderBy[i].id.toString() === this.intended_product.toString()) {
                    this.intended_index = i;
                  }
                }

                return orderBy;
            },
            hasProducts() {
                return _.size(this.products) > 0;
            },
            hasCollateral() {
              return _.size(this.collateral.collateral_type) > 0;
            },
            final_collateral_value() {
              let values = _.values(this.collateral.collateral_value);

              return _.filter(values, (c) => {
                return _.includes(this.collateral.collateral_type, c.id)
              })
            },
            cheapest() {
                return _.minBy(this.products, (product) => {
                    return product.tcc;
                })
            },
            expensive() {
                return _.maxBy(this.products, (product) => {
                    return product.tcc;
                })
            }
        },
        methods: {
            ...mapActions([Constants.SET_POSITION, Constants.FILTER_PRODUCTS, Constants.SET_LOAN, Constants.SET_COLLATERAL_FEES,]),
            populate(requirements) {
                this.house_location = requirements.house_location;
                this.business_location = requirements.business_location;
                this.time = requirements.time;
                this.payment_frequency = requirements.payment_frequency;
                this.loan_term = requirements.loan_term;
                this.loan_size = requirements.loan_size;
                this.card = requirements.card;
                this.loan_type = requirements.loan_type;
                this.respondent_id = requirements.respondent_id;
            },
            prepare() {
                if (this.loan) {
                    this.populate(this.loan);
                }
            },
            updateTerm() {
                this.v$.$touch();
                if (this.v$.$error) return;

                Firebase.sendEvent("Loan term and payment frequency updated");

                this.SET_LOAN({requirements: this._loan, position: false});

                this._filter();
            },
            updateCollateralFees() {
                const collateralType = this.collateral && this.collateral.collateral_type ? this.collateral.collateral_type : [];
                this.SET_COLLATERAL_FEES({collateral_type: collateralType});
            },
            previous() {
                Firebase.sendEvent("Products recommendations previous button clicked");
                this.SET_POSITION(3);
            },
            commas(num) {
                return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
            },
            table(active) {
                this.show_table = active;
            },
            inColumns(column) {
                return _.includes(this.columns, column);
            },
            fee_amount(fees, fee_name) {
                let fee = fees.find((item) => item.fee_name === fee_name);

                if (fee) {
                    if (fee.fee_amount_deducted)
                        return `Sh. ${this.commas(fee.fee_amount_deducted)}`;

                    if (fee.fee_amount_loan_percentage)
                        return `${fee.fee_amount_loan_percentage} %`;
                }

                return '';
            },
            collateral_fee_amount(fees, fee_name) {
                let fee = fees.find((item) => item.collateral_type_fee_name + ' ' + item.collateral_type_id === fee_name);

                if (fee) {
                    if (fee.collateral_fee_amount_deducted)
                        return `Sh. ${this.commas(fee.collateral_fee_amount_deducted)}`;

                    if (fee.collateral_fee_amount_loan_percentage)
                        return `${fee.collateral_fee_amount_loan_percentage} %`;
                }

                return '';
            },
            collateralFeeNamesById(id) {
              return _.map(this.collateralFees.filter((fee) => fee.collateral_type_id === id), (fee) => {
                return fee.collateral_type_fee_name + ' ' + fee.collateral_type_id;
              });
            },
            checkGroup(event) {
                const id = parseInt(event.target.value);
                if (this.checkCollateralGroup[id]) {
                  _.each(this.collateralFeeNamesById(id), (name) => {
                    if (!_.includes(this.columns, name)) {
                      this.columns.push(name);
                    }
                  });
                } else {
                  this.columns = _.filter(this.columns, (name) => {
                    return !_.includes(this.collateralFeeNamesById(id), name)
                  });
                }
            },
            _filter() {
                if (!this.processing) {
                    this.processing = true;
                    this.FILTER_PRODUCTS({
                        institution: this.institution,
                        personal: this.personal,
                        loan: this.loan,
                        collateral: this.collateral,
                        county: this.county,
                        institution_tier: this.institution_tier,
	                    qualification: this.qualification
                    }).then(() => {
                        this.processing = false;
                    }).then(() => {
                        this.prepare();
                    }).catch((error) => {
                        this.processing = false;
                        console.log(error.response);
                        console.log(JSON.stringify(error.response));
                    });
                }
            },
            showCompare(show) {
                this.show_compare = show;
            },
            compareNumber(number) {
                let shuffled = _.shuffle(this.productIds);
                this.compare = _.slice(shuffled, 0, number);

                this.showCompare(true);
            },
            getAccountName(product) {
                let acc = _.filter(product.account_types, (item) => {
                    return item.id.toString() === product.account_type.toString();
                });

                let first = _.head(acc);

                if (first)
                    return first.commercial_account_name;

                return '';
            },
            setDisplayMode(display_mode) {
                this.display_mode = display_mode;
            },
            capitalizeFirstLetter(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            }
        },
        watch: {
            institution() {
                this._filter();
            },
            county() {
                this._filter();
            },
            institution_tier() {
              this._filter();
            },
            checkLoanFees() {
                let cols = this.loanFeeNames;
                cols.push('tuc');

                if (this.checkLoanFees) {
                    _.each(cols, (name) => {
                        if (!_.includes(this.columns, name)) {
                            this.columns.push(name);
                        }
                    });
                } else {
                    this.columns = _.filter(this.columns, (name) => {
                        return !_.includes(cols, name)
                    });
                }
            },
            checkCollateralFees() {
                if (this.checkCollateralFees) {
                    //collateral.collateral_value
                    if(this.collateral.length !== 0 && this.collateral.collateral_value.length !== 0) {
                        _.each(this.collateral.collateral_value, (c) => {
                            this.checkCollateralGroup[c.id] = true;
                        });
                    }
                    _.each(this.collateralFeeNames, (name) => {
                        if (!_.includes(this.columns, name)) {
                            this.columns.push(name);
                        }
                    });
                } else {
                    this.checkCollateralGroup = [];
                    this.columns = _.filter(this.columns, (name) => {
                        return !_.includes(this.collateralFeeNames, name)
                    });
                }
            },
            "collateral.collateral_type": function (newVal, oldVal) {
                this.updateCollateralFees();
            },
            interestOptions() {
                let options = ['rate_weeks', 'rate_months', 'rate_years', 'basis'];
                if (this.interestOptions) {
                    this.columns.push('basis');
                } else {
                    this.columns = _.filter(this.columns, (name) => {
                        return !_.includes(options, name)
                    });
                }
            },
            accountFeeOptions() {
                let options = ['account-management', 'account-closing'];
                if (this.accountFeeOptions) {
                    _.each(options, (name) => {
                        if (!_.includes(this.columns, name)) {
                            this.columns.push(name);
                        }
                    });
                } else {
                    this.columns = _.filter(this.columns, (name) => {
                        return !_.includes(options, name)
                    });
                }
            },
            displayOptions() {
                let options = ['thermometer', 'table'];
                if (this.displayOptions) {
                    _.each(options, (name) => {
                        if (!_.includes(this.columns, name)) {
                            this.columns.push(name);
                        }
                    });
                } else {
                    this.columns = _.filter(this.columns, (name) => {
                        return !_.includes(options, name)
                    });
                }
            },
            amountsOptions() {
                let options = ['tcc', 'ols', 'iam', 'bank', 'momo', 'bch'];
                if (this.amountsOptions) {
                    _.each(options, (name) => {
                        if (!_.includes(this.columns, name)) {
                            this.columns.push(name);
                        }
                    });
                } else {
                    this.columns = _.filter(this.columns, (name) => {
                        return !_.includes(options, name)
                    });
                }
            },
            intended() {
              this.display_mode = 'short';
            },
            institutionOptions() {
                if(!this.institutionOptions) {
                    this.intended_institution = '';
                    this.county = [];
                }
            }
        }
    }
</script>

<style scoped>
table.p-table td {
  padding: 4px 5px;
  vertical-align: middle;
}
table.p-table td.pt-expand {
  cursor: pointer;
  padding: 15px 15px;
  text-align: center;
}
table.p-table td.pt-expand:after {
   content: '\2807';
   font-size: 24px;
}
.collateral-params .list-group-item {
  padding: 3px 8px;
}
.collateral-params > label {
  cursor:pointer;
}
.collapsed .la-arrow-collapse::before {
  content: "\25BC";
  font-style: normal;
}
</style>